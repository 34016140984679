import { useEffect, useState } from "react";
import { ArrowDropDown } from "../../../../../../assets/images";
import TransactionsTable from "../transactionsTable/TransactionsTable";
import "./AccountTransactionsCollapsibleRow.css";
import { ATReportGroupTransaction } from "../../../../../../types";
import { joinTransactionNumber } from "../../../../../../helpers/prefixHelper";
type Props = {
  filterData?:any;
  filterString?: string;
  group: any;
  groupBy: string;
  index: number;
};
const AccountTransactionsCollapsibleRow = ({
  filterData,
  filterString,
  groupBy,
  group,
  index,
}: Props) => {
  const [collapsed, setCollapsed] = useState(true);

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };
  useEffect(() => {
    setCollapsed(true);
  }, [JSON.stringify(group)]);

  const openingValuesRequiredArray: string[] = ["Account", "Account Group"];

  return (
    <>
      <tr id="account-transaction-table-head-row" onClick={toggleCollapse}>
        <td colSpan={10} className="account-transaction-table-head-td">
          <div className="account-transaction-table-head-wrap">
            <div className="account-transaction-table-head-row-1">
              <div
                id={`account-transaction-grouped-title-${index}`}
                className="account-transaction-table-head-row-1-head"
              >
                {joinTransactionNumber(group.group_name)}
              </div>
              <button
                type="button"
                className="accordion-arrow-btn no-appearance"
                aria-labelledby={`account-transaction-grouped-title-${index}`}
                aria-controls={`account-transaction-grouped-body-${index}`}
              >
                <img
                  src={ArrowDropDown}
                  className={`arrow-icon ${collapsed ? "collapsed" : ""}`}
                  alt="accordion show collapse button"
                />
              </button>
            </div>
            <div className="account-transaction-table-head-row-2">
              <div className="account-transaction-table-head-row-2-div">
                <div className="head">Total Gross: </div>
                <div>{group.total_gross}</div>
              </div>
              <div className="account-transaction-table-head-row-2-div">
                <div className="head">Total Debit: </div>
                <div>{group.total_debit}</div>
              </div>
            </div>
            <div className="account-transaction-table-head-row-2">
              {filterData.group_by === "Account" ||
              filterData.group_by === "Account Group" ? (
                <div className="account-transaction-table-head-row-2-div">
                  <div className="head">Total Running Balance: </div>
                  <div>{group.total_running_balance}</div>
                </div>
              ) : (
                ""
              )}

              <div className="account-transaction-table-head-row-2-div">
                <div className="head">Total Credit: </div>
                <div>{group.total_credit}</div>
              </div>
            </div>
          </div>
        </td>
      </tr>
      <tr className="accordion-collapsible-row">
        <td
          className="accordion-collapsible-col"
          colSpan={10}
          id={`account-transaction-grouped-body-${index}`}
        >
          {!collapsed && (
            <TransactionsTable
              key={`${group.group_name}${group.total_gross}${
                group.total_debit
              }{group.total_credit}${
                group.filter
              }${Math.random()}attableGroup-${index}`}
              filterString={filterString}
              filterData={filterData}
              filter={group.filter}
              openingBalance={
                openingValuesRequiredArray.includes(groupBy)
                  ? String(group.opening_balance)
                  : undefined
              }
              closingBalance={
                openingValuesRequiredArray.includes(groupBy)
                  ? String(group.closing_balance)
                  : undefined
              }
            />
          )}
        </td>
      </tr>
    </>
  );
};

export default AccountTransactionsCollapsibleRow;
