import { useState } from "react";
import {
  FormInputField,
  FormSelectField,
  SaveButton,
} from "../../../../common/components";
import { defaultValues } from "../../../customerManagement/components/customerForm/StateInitialization";
import { ObjectType } from "../../../../../types";
import { customSelectStyle } from "../../../../common/components/SelectCustomStyle";
import TransactionHeader from "../../../../common/components/TransactionHeader";
import FiscalYearSelect from "../fiscalYearSelect/FiscalYearSelect";

export const BudgetFilter = () => {
  const [budgetName, setBudgetname] = useState("");
  const [defaultData, setDefaultData] = useState(defaultValues);
  const [formErrors, setFormErrors] = useState<ObjectType>({});
  const [period, setPeriod] = useState("");

  const handleChange = (data: { name: string; value: string | number }) => {
    let { name, value } = data;
    setDefaultData({ ...defaultData, [name]: value });
  };

  return (
    <>
      <div
        className="card card-user-management card-customer card-create-item main-card overflowX-hidden h-100 card-bill-management"
        id="invoice-create"
      >
        <div className="card-header card-user-management-header card-customer-header card-no-bg-header card-main-header">
          <TransactionHeader
            transactionName="Budget"
            // transactionNumber={Number(editId)}
            transactionNumber={0}
          />
        </div>
        <form
          // onSubmit={handleSubmit}
          name="add_customer_form"
          id="add_customer_form"
          tabIndex={-1}
          className="add-consumer-form customer-form-wrap"
        >
          <div className="fields-wrapper">
            <section className="form-top-section">
              <div className="row custom-row">
                <FormInputField
                  type="text"
                  name="customer_display_name"
                  id="c_display_name"
                  className={
                    formErrors.customer_display_name
                      ? "form-control error"
                      : "form-control"
                  }
                  placeholder="Enter budget name"
                  label="Budget Name"
                  value={defaultData.customer_display_name}
                  onChange={(e) => handleChange(e.target)}
                  onInput={(e: any) => setBudgetname(e?.target?.value)}
                  maxLength={200}
                  isRequired={true}
                  // disabled={customerId ? true : false}
                  disabled={false}
                  error={formErrors.customer_display_name}
                />

                <div className="col-12 col-lg-4 mw-325">
                  <div className="link-label-grouped">
                    <label htmlFor="customer" className="required">
                      Start
                    </label>
                  </div>
                  <div>
                    <FiscalYearSelect />
                  </div>
                </div>

                <div className="col-12 col-lg-4 mw-325">
                  <FormSelectField
                    name="repeat_every"
                    id="repeat_every"
                    label="Actuals"
                    options={[
                      { label: "None", value: "none" },
                      { label: "3 Months", value: "3" },
                      { label: "6 Months", value: "6" },
                      { label: "12 Months", value: "12" },
                    ]}
                    wrapperClass="mw-325"
                    className="state-select custom-select"
                    value={""}
                    onChange={(option: any) => {
                      setPeriod(option);
                    }}
                    styles={customSelectStyle}
                    placeholder={"Actuals"}
                    isSearchable={true}
                    isDisabled={false}
                    error={""}
                    isRequired={true}
                    isOnlyInRow={false}
                  />
                </div>
                <div className="col-12 col-lg-4 mw-325">
                  <FormSelectField
                    name="repeat_every"
                    id="repeat_every"
                    label="Period"
                    options={[
                      { label: "None", value: "none" },
                      { label: "3 Months", value: "3" },
                      { label: "6 Months", value: "6" },
                      { label: "12 Months", value: "12" },
                      { label: "24 Months", value: "24" },
                    ]}
                    wrapperClass="mw-325"
                    className="state-select custom-select"
                    value={""}
                    onChange={(option: any) => {
                      setPeriod(option);
                    }}
                    styles={customSelectStyle}
                    placeholder={"Period"}
                    isSearchable={true}
                    isDisabled={false}
                    error={""}
                    isRequired={true}
                    isOnlyInRow={false}
                  />
                </div>




                {/* <div
                  className={`button-container d-flex ${
                    ["CBD", "VBD"].includes(props.reportType)
                      ? "customer-vendor-balance-btn-wrapper"
                      : ""
                  }`}
                >
                  <SaveButton
                    type="button"
                    buttonClassName={`save-btn form-btn ${
                      ["CBD", "VBD"].includes(props.reportType)
                        ? "customer-vendor-balance-save-btn"
                        : ""
                    }`}
                    onClick={() => {
                      if (
                        props.reportType === "TB" ||
                        props.reportType === "BS"
                      ) {
                        dispatch(setSelectedTagsData(selectedTags));
                      }
                      props.onSubmit && props.onSubmit();
                      applyFilter();
                    }}
                    isDisabled={
                      !getName(currencyListOptions, filterValues.currency_id)
                    }
                    buttonContent="Apply"
                  />
                  <a
                    href=""
                    className="role-cancel text-decoration-none cancel-btn form-btn"
                    onClick={(e) => {
                      e.preventDefault();
                      resetRedux();
                      clearFilter();
                      setSelectedTags([]);
                      clearStoredInfoInLocal();
                      props.clearReport();
                    }}
                  >
                    Reset
                  </a>
                </div> */}




              </div>
            </section>
          </div>
        </form>
      </div>
    </>
  );
};
