import React, { useEffect, useState, useRef, useCallback } from "react";
import { ArrowDropBlue } from "../../../../assets/images";
import { useAuditTrail } from "./hooks/useAuditTrail";
import AuditLogTableContainer from "./AuditLogTableContainer";
import Loader from "../../../home/components/Loader";
import "./AuditTrail.css";

type Props = {
  title: string;
  transactionType: string;
  transactionId: number;
  reRenderFlag?: number;
};
type History = {
  details: string;
  oldValue: string;
  newValue: string;
};
type Row = {
  dateAndTime: JSX.Element;
  action: string;
  updatedBy: string;
  history: History[];
};
const AuditTrail: React.FC<Props> = ({
  title,
  transactionType,
  transactionId,
}) => {
  const [rows, setRows] = useState<Row[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [prevPageToken, setPrevPageToken] = useState<string | null>(null);
  const [hasMore, setHasMore] = useState(true);
  const observerRef = useRef<IntersectionObserver | null>(null);

  const { auditTrail } = useAuditTrail();

  const fetchAuditData = useCallback(
    (pageToken: string | null) => {
      auditTrail({
        pageToken,
        setHasMore,
        setIsLoading,
        setPrevPageToken,
        setRows,
        rows,
        transactionId,
        transactionType,
      });
    },
    [auditTrail, transactionId, transactionType, rows]
  );

  useEffect(() => {
    fetchAuditData(null);
  }, [fetchAuditData]);

  const lastRowRef = useCallback(
    (node: HTMLElement | null) => {
      if (isLoading || !hasMore) return;
      if (observerRef.current) observerRef.current.disconnect();
      observerRef.current = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting) {
            fetchAuditData(prevPageToken);
          }
        },
        { threshold: 1.0 }
      );

      if (node) observerRef.current.observe(node);
    },
    [isLoading, hasMore, prevPageToken, fetchAuditData]
  );

  const headers = ["DATE & TIME", "ACTION", "UPDATED BY"];
  const viewDetailsText = "View Details";
  const hideDetailsText = "Hide Details";
  const viewDetailsImage = ArrowDropBlue;
  const detailsHeaders = ["DETAILS", "OLD VALUE", "NEW VALUE"];

  return (
    <div
      className={title === "Audit Trail" ? "audit-view-container" : ""}
      id="audit-view-container"
    >
      <div className="jv-postings-section">
        <h2>{title}</h2>
        <div className="table-section">
          {isLoading && <Loader />}
          <AuditLogTableContainer
            headers={headers}
            rows={rows}
            detailsColumn="details"
            viewDetailsText={viewDetailsText}
            hideDetailsText={hideDetailsText}
            viewDetailsImage={viewDetailsImage}
            detailsHeaders={detailsHeaders}
            lastRowRef={lastRowRef}
          />
        </div>
      </div>
    </div>
  );
};

export default AuditTrail;
