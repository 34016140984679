import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../../../app/hooks";
import { usePermissionHandler } from "../../../../hooks/usePermissionHandler";
import { usePaginationDispatch } from "../../../../hooks/usePaginationDispatch";
import { Transactionlimit } from "../../../../../types";
import { currentUserSelector } from "../../../../common/commonSlice";
import Pagination from "../../../../common/components/table/Pagination";
import ListHeader from "../../../../common/components/list/ListHeader";
import useListData from "../../../../hooks/useListData";
import NotificationModal from "../../../../common/components/NotificationModal";
import "../../../../common/styles/ListTable.css";

const BudgetList = () => {
  const navigate = useNavigate();
  const listRef = useRef<any>([]);
  const filterRef = useRef<any>([]);
  const currentUserInfo = useAppSelector(currentUserSelector);
  const { setPage, setItemOffset } = usePaginationDispatch();
  const { journalVoucherRolePermission } = usePermissionHandler();
  const [totalJournals, setTotalJournals] = useState(0);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [transactionLimit, setTransactionLimit] = useState<Transactionlimit>({
    limit_exceed: false,
    limit_count: "",
  });
  const { page, pageCount, itemsPerPage, totalList, dateSortOrder } =
    useListData();

  // Invoke when user click to request another page.
  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % totalJournals;
    setPage(event.selected + 1);
    setItemOffset(newOffset);
  };

  const handleApplyFilter = () => {
    setIsFilterApplied(true);
  };

  return (
    <>
      <div className="card card-user-management card-customer main-card overflowX-hidden h-100">
        <ListHeader
          title="Budgets"
          haveSearchBox={false}
          haveCreateButton={true}
          createButtonLabel="Add New Budget"
          createOnClick={() => {
            transactionLimit.limit_exceed
              ? $("#transactionLimitModal").modal("show")
              : navigate("/budget/create");
          }}
          filterType=""
          roles={journalVoucherRolePermission} //Need to update after permission Matrix gets updated
          ref={filterRef}
          module="Budget"
          isFiscalYearFilterRequired={false}
          haveFilter = {false}
        />
        <div className="card-body-wrapper d-flex h-100 overflowX-hidden">
          <div className="card-body-wrapper">
            <div className="card-footer clear-both">
              {totalList > itemsPerPage ? (
                <Pagination
                  pageCount={pageCount}
                  page={page}
                  handlePageClick={handlePageClick}
                />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
      <NotificationModal
        modalId="transactionLimitModal"
        modalHeader={"Transaction limit exceeded!"}
        modalBody={`You have crossed the allotted transaction limit of ${transactionLimit.limit_count}. Please contact your administrator or get in touch with us for more assistance.`}
        modalSubmit="OK"
        modalCancel=""
        modalSubmitHandler={() => {}}
      />
    </>
  );
};

export default BudgetList;
