import { useState, useEffect } from "react";
import { useAppSelector } from "../../../../../app/hooks";
import { currentUserSelector } from "../../../../common/commonSlice";
import { customSelectStyle } from "../../../../common/components/SelectCustomStyle";
import Select from "react-select";

type Option = { label: string; value: string };

function FiscalYearSelect() {
  const currentUserInfo = useAppSelector(currentUserSelector);
  const [options, setOptions] = useState<Option[]>([]);
  const [selectedOption, setSelectedOption] = useState<Option | null>(null);

  useEffect(() => {
    const fiscalEndDate = new Date(currentUserInfo.previous_fiscal_end_date);
    const fiscalYearEndMonth = fiscalEndDate.getMonth();
    const fiscalYearEndYear = fiscalEndDate.getFullYear();

    // Helper to get fiscal year string in 'MMM YYYY' format
    const getFiscalYearString = (month: number, year: number): string => {
      const date = new Date(year, month, 1);
      return date.toLocaleString("default", {
        month: "short",
        year: "numeric",
      });
    };

    // Current fiscal year
    const currentYearOptions: Option[] = [];
    for (let i = fiscalYearEndMonth; i < 12; i++) {
      currentYearOptions.push({
        label: getFiscalYearString(i, fiscalYearEndYear - 1),
        value: `${i}-${fiscalYearEndYear - 1}`,
      });
    }
    for (let i = 0; i <= fiscalYearEndMonth; i++) {
      currentYearOptions.push({
        label: getFiscalYearString(i, fiscalYearEndYear),
        value: `${i}-${fiscalYearEndYear}`,
      });
    }

    // Next fiscal year
    const nextYearOptions: Option[] = [];
    for (let i = fiscalYearEndMonth + 1; i < 12; i++) {
      nextYearOptions.push({
        label: getFiscalYearString(i, fiscalYearEndYear),
        value: `${i}-${fiscalYearEndYear}`,
      });
    }
    for (let i = 0; i <= fiscalYearEndMonth; i++) {
      nextYearOptions.push({
        label: getFiscalYearString(i, fiscalYearEndYear + 1),
        value: `${i}-${fiscalYearEndYear + 1}`,
      });
    }

    const allOptions = [...currentYearOptions, ...nextYearOptions];
    setOptions(allOptions);
    setSelectedOption(allOptions[0]);
  }, [currentUserInfo]);

  console.log("Options : ", options);

  return (
    <Select
      name="fiscal_year_select"
      id="fiscal_year_select"
      value={selectedOption}
      options={options}
      placeholder="Select Fiscal Year"
      onChange={(selected) => setSelectedOption(selected as Option)}
      className="form-select custom-select fiscal_year_filter"
      styles={customSelectStyle}
      isDisabled={false}
    />
  );
}

export default FiscalYearSelect;
