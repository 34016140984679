import { useCallback, useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { getFormatedDate } from "../../../../helpers/helper";
import { message } from "../../../constants/messages";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { currentUserSelector } from "../../../common/commonSlice";
import { CustomizeReportModal } from "../../../common/components/reportCustomizationModal/CustomizeReportModal";
import { getFiscalQuarterYear } from "../../../../helpers/fiscalYearHelper";
import {
  getAccountTransactionReport,
  getAccountTransactionReportNew,
  reportCustomizationFilterSelector,
  setReportCustomizationFilters,
} from "../ReportSlice";
import {
  ATReportGroupedTransaction,
  ATReportGroupTransaction,
  ATReportTransaction,
  ATReportTransactionDetails,
  ATReportTransactionGroupDetails,
  ObjectType,
} from "../../../../types";
import ReportHeader from "../componets/ReportHeader";
import ReportFilter from "../componets/ReportFilter";
import BackButton from "../../../common/components/BackButton";
import InfoBox from "../../../common/components/infoBox/InfoBox";
import AccountTransactionTable from "./components/AccountTransactionTable";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "../../components/Loader";
import "./AccountTransactions.css";

function AccountTransaction() {
  type LocationProps = {
    accTransactionFilter: ObjectType;
  };
  const location = useLocation();
  const dispatch = useAppDispatch();
  const reportRef = useRef<any>([]);
  const currentUserInfo = useAppSelector(currentUserSelector);
  const [hasMore, setHasMore] = useState(true);
  const [pageNo, setPageNo] = useState(1);
  const locationState = location.state as LocationProps;
  const accTransactionFilter = locationState?.accTransactionFilter
    ? locationState.accTransactionFilter
    : {};
  const accTransFormFilter = useAppSelector(
    reportCustomizationFilterSelector
  ).accountTransactionFilters;
  const [filterString, setFilterString] = useState("");
  const hashFragment = location.hash;
  // Remove the '#' character from the hash fragment
  const decodedFilterString = hashFragment
    ? decodeURIComponent(hashFragment.slice(1))
    : undefined;
  // // Parse the filter data as needed
  const filters = decodedFilterString
    ? JSON.parse(decodedFilterString)
    : undefined;

  const initialAccountTransactionData = {
    currency_id: 0,
    currency_code: "INR",
    date_range: "today",
    organization_id: 0,
    start_date: getFormatedDate(),
    end_date: getFormatedDate(),
    items: [],
    organization_details: {
      organization_name: "",
      email: "",
      country: "",
      state: "",
      id: 0,
      last_login: false,
      name: "",
      parent_hierarchy: "",
    },
    group_by: "",
    report_basis: "",
    transactions: [],
  };

  const initialAccountTransactionGroupData = {
    currency_id: 0,
    currency_code: "INR",
    date_range: "today",
    organization_id: 0,
    start_date: getFormatedDate(),
    end_date: getFormatedDate(),
    items: [],
    organization_details: {
      organization_name: "",
      email: "",
      country: "",
      state: "",
      id: 0,
      last_login: false,
      name: "",
      parent_hierarchy: "",
    },
    group_by: "",
    report_basis: "",
    groups: [],
  };

  const initialFiltervalues = {
    currency_id: filters
      ? filters.currency_id
      : currentUserInfo.organization_currency,
    date_range: filters ? filters.date_range : "this_quarter",
    end_date: filters
      ? filters.end_date
      : getFiscalQuarterYear(
          currentUserInfo.organization_fiscal_year
            ? currentUserInfo.organization_fiscal_year
            : "",
          "Current"
        ).endDate,
    organization_id: filters
      ? filters.organization_id
      : currentUserInfo.organization_id,
    start_date: filters
      ? filters.start_date
      : getFiscalQuarterYear(
          currentUserInfo.organization_fiscal_year
            ? currentUserInfo.organization_fiscal_year
            : "",
          "Current"
        ).startDate,
    account_id: filters ? filters.account_id : null,
    tag_option_in: filters ? filters.tag_option_in : [],
  };

  const [showReport, setShowReport] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const newLoadRef = useRef(true);
  const [accountTransactionData, setAccountTransactionData] =
    useState<ATReportTransactionDetails>(initialAccountTransactionData);
  const [accountTransactionGroupData, setAccountTransactionGroupData] =
    useState<ATReportTransactionGroupDetails>(
      initialAccountTransactionGroupData
    );
  let drillDownDetails: any = localStorage.getItem("drill-down-report");
  if (drillDownDetails) drillDownDetails = JSON.parse(drillDownDetails);

  useEffect(() => {
    return () => {
      dispatch(
        setReportCustomizationFilters({
          accountTransactionFilters: {
            start_date: "",
            end_date: "",
            date_range: "",
            report_basis: "Accrual",
            group_by: "",
            get_groups: false,
            advanced_filter: [],
            tag_option_in: [],
          },
        })
      );
    };
  }, []);

  useEffect(() => {
    if (
      Object.keys(accTransactionFilter).length < 1 &&
      currentUserInfo.organization_id &&
      currentUserInfo.organization_currency
    )
      getAccountTransactionsReports("initial", {});
  }, [currentUserInfo.organization_id, currentUserInfo.organization_currency]);

  useEffect(() => {
    getAccountTransactionsReports("drilldown", {});
  }, [accTransactionFilter]);

  const handleReport = (type: string, argFilters: any = {}) => {
    newLoadRef.current = true;
    setAccountTransactionData(initialAccountTransactionData);
    setHasMore(true);
    setPageNo(1);
    setIsLoading(true);
    getAccountTransactionsReports("submit", argFilters, 1);
  };
  /**
   * Function to get Account Transaction report
   */
  const getAccountTransactionsReports = useCallback(
    async (type: string, argFilters = {}, pageNum?: number) => {
      /**
       * To avoid multiple api call is being called with initial data.
       */
      if (type === "initial") {
        if (
          reportRef.current.filterValues.date_range !== "this_quarter" &&
          Object.keys(accTransactionFilter).length > 0
        ) {
          return;
        }
      }

      if (type === "drilldown") {
        if (
          !Object.keys(JSON.parse(JSON.stringify(accTransactionFilter))).length
        ) {
          return;
        }
      }

      let filterValues = {
        ...reportRef.current.filterValues,
        ...accTransFormFilter,
        ...argFilters,
      };

      if (type === "initial") filterValues = initialFiltervalues;
      if (Object.keys(accTransactionFilter).length && type === "drilldown") {
        filterValues = {
          currency_id: accTransactionFilter.currencyId,
          organization_id: accTransactionFilter.organizationId,
          start_date: accTransactionFilter.startDate,
          end_date: accTransactionFilter.endDate,
          date_range: accTransactionFilter.dateRange,
          account_id: accTransactionFilter.accountId,
          tag_option_in: accTransactionFilter.tag_option_in
            ? accTransactionFilter.tag_option_in
            : filters
            ? filters.tag_option_in
            : [],
        };
      }

      const tempFilterString = JSON.stringify(filterValues);
      const hashFragment = `#${encodeURIComponent(tempFilterString)}`;
      const payLoad = filterValues;
      setFilterString(hashFragment);
      if (payLoad.group_by === "") {
        delete payLoad.group_by;
      }
      if (payLoad.group_by && !payLoad.get_groups) {
        payLoad.get_groups = true;
      }
      if (payLoad.get_groups && payLoad.group_by) {
        if (payLoad.group_filter) {
          delete payLoad.group_filter;
        }
      }
      const responseAction = await dispatch(
        getAccountTransactionReportNew({
          filterValues: payLoad,
          orgId: currentUserInfo.organization_id,
          pageNum: pageNum || pageNo,
          pageSize: 100,
        })
      );
      if (responseAction.payload) {
        const response = responseAction.payload;
        if (Object.keys(response).length && !("error" in response)) {
          if (response.pagination?.next === null) {
            setHasMore(false);
          } else {
            setPageNo((value) => {
              return value + 1;
            });
          }
          let tempData: ObjectType[] = [];
          let temp: ObjectType[] = [];
          temp = accountTransactionData.transactions?.map(
            (transaction: any) => {
              tempData.push(transaction);
              return transaction;
            }
          );
          setIsLoading(false);
          response.group_by
            ? setAccountTransactionGroupData({ ...response })
            : setAccountTransactionData(
                getUpdatedATData(
                  pageNo === 1
                    ? initialAccountTransactionData
                    : accountTransactionData,
                  response.transaction_details
                )
              );
          setShowReport(true);
        } else {
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }
    },
    [accTransactionFilter, accTransFormFilter]
  );

  // funtion to update data with the new data to manage infinite scroll setup
  const getUpdatedATData = (
    previousData: ATReportTransactionDetails,
    newData: ATReportTransactionDetails
  ) => {
    let newtransactionsData: (
      | ATReportGroupedTransaction
      | ATReportTransaction
    )[] = [];
    let combinedData: ATReportTransactionDetails =
      initialAccountTransactionData;
    if (newLoadRef.current) {
      combinedData = {
        ...newData,
      };
      newLoadRef.current = false;
    } else if (
      !newData.transactions.some(
        (transaction: ATReportGroupedTransaction | ATReportTransaction) =>
          (transaction as ATReportGroupedTransaction).hasOwnProperty(
            "group_name"
          )
      ) &&
      !newLoadRef.current
    ) {
      combinedData = {
        ...previousData,
        transactions: [...previousData.transactions, ...newData.transactions],
      };
    } else {
      let groupedArray: ObjectType = {};
      if (
        previousData.transactions.some((trans) =>
          trans.hasOwnProperty("group_name")
        )
      ) {
        newtransactionsData = previousData.transactions
          .map(
            (transaction: ATReportGroupedTransaction | ATReportTransaction) => {
              groupedArray[
                (transaction as ATReportGroupedTransaction).group_name
              ] = {
                ...(transaction as ATReportGroupedTransaction)
                  .transactions_list,
              };
              return {
                group_name: (transaction as ATReportGroupedTransaction)
                  .group_name,
                transactions_list: (
                  transaction as ATReportGroupedTransaction
                ).transactions_list.concat(
                  (newData.transactions as ATReportGroupedTransaction[])
                    .filter(
                      (filteredData) =>
                        (transaction as ATReportGroupedTransaction)
                          .group_name ===
                        (filteredData as ATReportGroupedTransaction).group_name
                    )

                    .flatMap(
                      (flatData) =>
                        (flatData as ATReportGroupedTransaction)
                          .transactions_list
                    )
                ),
              };
            }
          )
          .concat(
            (newData.transactions as ATReportGroupedTransaction[]).filter(
              (filteredData) =>
                !Object.keys(groupedArray).includes(filteredData.group_name)
            )
          );
      } else {
        newtransactionsData = newData.transactions;
      }
      combinedData = {
        ...previousData,
        transactions: [...newtransactionsData] as ATReportGroupedTransaction[],
      };
    }
    return combinedData;
  };

  const getUpdatedGroupedATData = (
    previousData: ATReportTransactionGroupDetails,
    newData: ATReportTransactionGroupDetails
  ) => {
    let newtransactionsData: ATReportGroupTransaction[] = [];
    let combinedData: ATReportTransactionGroupDetails =
      initialAccountTransactionGroupData;
    if (newLoadRef.current) {
      combinedData = {
        ...newData,
      };
      newLoadRef.current = false;
    } else {
      let groupedArray: ObjectType = {};
      newtransactionsData = newData.groups;
      combinedData = {
        ...previousData,
        groups: [...newtransactionsData] as ATReportGroupTransaction[],
      };
    }
    return combinedData;
  };

  return (
    <div className="reports account-transaction-report">
      <div
        className="card card-dashboard report-card account-transaction-card w-100"
        id="soa-report-card"
      >
        <div>
          {drillDownDetails ? (
            <div className="back-navigation">
              <Link to={drillDownDetails.url}>
                <BackButton onClick={() => {}} label=" " />
                <span className="back-nav-label">
                  Back to {drillDownDetails.label}
                </span>
              </Link>
            </div>
          ) : (
            ""
          )}
          <div className="card-header p-0 border-0">
            <div className="header-wrap">
              <h1>Account Transactions</h1>
              <CustomizeReportModal
                reportType="AT"
                handleReport={handleReport}
                onSubmit={() => {
                  newLoadRef.current = true;
                }}
              />
            </div>
          </div>
          <div className="report-body w-100">
            <div className="top-filter-container filter-box w-100">
              <ReportFilter
                isSingleDatePicker={false}
                isAdvanced={true}
                defaultValue="quarter"
                reportType="AT"
                handleReport={handleReport}
                clearReport={() => setShowReport(false)}
                onSubmit={() => {
                  newLoadRef.current = true;
                }}
                ref={reportRef}
                filterdata={accTransactionFilter}
              />
            </div>
            {showReport ? (
              <div className="report-section w-100">
                <div className="report-body-links justify-content-between align-items-center "></div>
                <ReportHeader
                  reportData={
                    accountTransactionGroupData.group_by
                      ? accountTransactionGroupData
                      : accountTransactionData
                  }
                  reportName={"Account Transactions"}
                />
                <div className="table-section report-table-section">
                  <InfoBox
                    message={
                      message(
                        accountTransactionGroupData.group_by
                          ? accountTransactionGroupData.currency_code
                          : accountTransactionData.currency_code
                      ).reportCurrencyInfo
                    }
                    className="report-note"
                  />
                  <AccountTransactionTable
                    key={"at1"}
                    accountTransactionData={accountTransactionData}
                    hasMore={hasMore}
                    getAccountTransactionsReports={
                      getAccountTransactionsReports
                    }
                    accountTransactionGroupData={accountTransactionGroupData}
                    filterString={filterString}
                    isGroup={accTransFormFilter.group_by ? true : false}
                  />
                </div>
              </div>
            ) : null}
            {isLoading ? <Loader /> : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AccountTransaction;
