import React, { useEffect, useRef, useState, useCallback } from "react";
import { useAppSelector } from "../../../../../app/hooks";
import { Transactionlimit } from "../../../../../types";
import { dateSortOrderSelector } from "../../../../../appSlice";
import { usePaginationDispatch } from "../../../../hooks/usePaginationDispatch";
import { usePermissionHandler } from "../../../../hooks/usePermissionHandler";
import useCommonData from "../../../../hooks/useCommon";
import PurchaseOrderTableContainer from "./purchaseOrderTable/purchaseOrderTableContainer";
import useListData from "../../../../hooks/useListData";
import ListHeader from "../../../../common/components/list/ListHeader";
import TransactionLimitModal from "../../../../common/components/TransactionLimitModal";
import Pagination from "../../../../common/components/table/Pagination";
import DeletePurchaseOrder from "../Delete";
import "../../../../common/styles/ListTable.css";
import "../../PurchaseOrder.css";

const PurchaseOrderList = () => {
  const dateSortOrder = useAppSelector(dateSortOrderSelector);
  const { dispatch, navigate, currentUserInfo } = useCommonData();
  const { setPage, setItemOffset } = usePaginationDispatch();
  const { page, pageCount, itemsPerPage, totalList } = useListData();
  const { purchaseOrderRolePermission } = usePermissionHandler();
  const [deletePurchaseOrderItemId, setDeletePurchaseOrderItemId] =
    useState("");
  const [deletePurchaseOrderId, setDeletePurchaseOrderId] = useState(-1);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [transactionLimit, setTransactionLimit] = useState<Transactionlimit>({
    limit_exceed: false,
    limit_count: "",
  });
  const listRef = useRef<any>([]);
  const filterRef = useRef<any>([]);

  useEffect(() => {
    setIsFilterApplied(false);
  }, [currentUserInfo.organization_id]);

  useEffect(() => {
    ($(".selectpicker") as any).selectpicker("refresh");
  }, []);
  // Invoke when user click to request another page.
  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % totalList;
    setPage(event.selected + 1);
    setItemOffset(newOffset);
  };
  /**
   * Set delete data details
   */
  const deleteListData = (id: number, listNum: string) => {
    setDeletePurchaseOrderId(id);
    setDeletePurchaseOrderItemId(listNum);
  };

  const handleApplyFilter = (value: boolean) => {
    setIsFilterApplied(value);
  };

  const refreshList = () => {
    filterRef.current.refreshFilter();
  };

  return (
    <>
      <div className="card card-user-management card-customer main-card overflowX-hidden h-100">
        <ListHeader
          title="Purchase Order"
          haveSearchBox={false}
          haveCreateButton={true}
          createButtonLabel="Create Purchase Order"
          createOnClick={() => {
            transactionLimit.limit_exceed
              ? $("#transactionLimitModal").modal("show")
              : navigate("/purchase-order/create");
          }}
          haveFilter={true}
          filterType="Purchase Orders"
          applyFilter={handleApplyFilter}
          roles={purchaseOrderRolePermission}
          ref={filterRef}
          module="Purchase Orders"
          isFiscalYearFilterRequired={false}
        />
        <div className="card-body-wrapper d-flex h-100 overflowX-hidden">
          <PurchaseOrderTableContainer
            transactionLimit={transactionLimit}
            initializeFilter={refreshList}
            isFilterApplied={isFilterApplied}
            deleteListData={deleteListData}
            ref={listRef}
          />
          <div className="card-footer clear-both">
            {totalList > itemsPerPage ? (
              <Pagination
                pageCount={pageCount}
                page={page}
                handlePageClick={handlePageClick}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <DeletePurchaseOrder
        deletePurchaseOrderId={deletePurchaseOrderId}
        deletePurchaseOrderItemId={deletePurchaseOrderItemId}
        page={page}
        itemsPerPage={itemsPerPage}
        dateSortOrder={dateSortOrder}
        refreshPurchaseOrders={refreshList}
        organizationId={currentUserInfo.organization_id}
      />
      <TransactionLimitModal limit={transactionLimit.limit_count} />
    </>
  );
};

export default React.memo(PurchaseOrderList);
