import { useNavigate, useParams } from "react-router-dom";
import { SuccessToaster } from "../../../../../helpers/toastHelper";
import { JournalItemValues, JournalValues } from "../../../../../types";
import { setLoaderState } from "../../../../common/commonSlice";
import useCommonData from "../../../../hooks/useCommon";
import {
  createSystemVoucherVoucher,
  deleteSystemVoucherItemDetails,
  deleteSystemVoucherRecipientDetails,
  editSystemVoucherDetails,
} from "../../systemVoucherSlice";
import { payloadRefiner } from "../helpers/payloadRefiner";
import { createJournalVoucher } from "../../../journalVoucher/journalVoucherSlice";

export const useSVFormSubmission = () => {
  const { currentUserInfo, dispatch } = useCommonData();
  const navigate = useNavigate();
  const { editId } = useParams();
  /**
   * Create new system voucher
   */
  const createSystemVoucherSubmit = async (
    systemVoucherPayload: any,
    systemVoucherlStatus: string,
    action: string,
    listInJv: boolean
  ) => {
    dispatch(setLoaderState(true));
    let createResponseAction;
    if (listInJv) {
      createResponseAction = await dispatch(
        createJournalVoucher({
          values: payloadRefiner(
            systemVoucherPayload,
            "create"
          ) as JournalValues,
          status: systemVoucherlStatus,
          orgId: currentUserInfo.organization_id,
        })
      );
    } else {
      createResponseAction = await dispatch(
        createSystemVoucherVoucher({
          values: payloadRefiner(
            systemVoucherPayload,
            "create"
          ) as JournalValues,
          status: systemVoucherlStatus,
          orgId: currentUserInfo.organization_id,
        })
      );
    }
    const createResponse = createResponseAction.payload;
    if (Object.keys(createResponse).length && !("error" in createResponse)) {
      dispatch(setLoaderState(false));
      if (systemVoucherlStatus === "Draft") navigate(-1);
      else
        navigate(`/system-voucher/detail/${createResponse.id}`, {
          replace: true,
        });

      SuccessToaster(
        "System Voucher created successfully!",
        "system-voucher-create-success"
      );
    } else if ("error" in createResponse) {
      dispatch(setLoaderState(false));
    } else {
      dispatch(setLoaderState(false));
    }
  };

  const updateSystemVoucherSubmit = async (
    systemVoucherPayload: any,
    systemVoucherStatus: string,
    deleteItems: any,
    deleteEmailIds: string[]
  ) => {
    dispatch(setLoaderState(true));
    if (Array.isArray(deleteItems) && deleteItems.length) {
      for (let item of deleteItems) {
        let res = await dispatch(
          deleteSystemVoucherItemDetails({
            systemVoucherId: Number(editId),
            itemId: Number(item.itemId as any),
            orgId: currentUserInfo.organization_id,
          })
        );
      }
    }
    if (Array.isArray(deleteEmailIds) && deleteEmailIds.length) {
      let deleteContactIds: number[] = [];
      deleteEmailIds.map((emaiId) => {
        deleteContactIds.push(Number(emaiId));
      });
      if (deleteContactIds.length) {
        await dispatch(
          deleteSystemVoucherRecipientDetails({
            systemVoucherId: Number(editId),
            emailIds: deleteContactIds,
            orgId: currentUserInfo.organization_id,
          })
        );
      }
    }

    const updateResponseAction = await dispatch(
      editSystemVoucherDetails({
        id: Number(editId),
        values: payloadRefiner(systemVoucherPayload, "edit") as JournalValues,
        status: systemVoucherStatus,
        orgId: currentUserInfo.organization_id,
      })
    );
    const updateResponse = updateResponseAction.payload;
    if (Object.keys(updateResponse).length && !("error" in updateResponse)) {
      dispatch(setLoaderState(false));
      if (systemVoucherStatus === "Draft") navigate(-1);
      else
        navigate(`/system-voucher/detail/${Number(editId)}`, {
          replace: true,
        });
      SuccessToaster(
        "System voucher updated successfully!",
        "system-voucher-update-success"
      );
    } else if ("error" in updateResponse) {
      dispatch(setLoaderState(false));
    } else {
      dispatch(setLoaderState(false));
    }
  };
  return { createSystemVoucherSubmit, updateSystemVoucherSubmit };
};
