import React, {
  Dispatch,
  ForwardRefRenderFunction,
  SetStateAction,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { getFormatedDate } from "../../../../../helpers/helper";
import { FormInputField } from "../../../../common/components/formInputField/FormInputField";
import { FormInputFieldsInRows } from "../../../../common/components/formInputFieldInRows/FormInputFieldInRows";
import { PaymentTermsModal } from "../../../../common/components/paymentTerms/paymentTermsModal";
import { useSubModulePermission } from "../../../../hooks/useSubModulePermissions";
import { AddCustomerModal } from "../../../customerManagement/components/addCustomerModal/AddCustomerModal";
import { initialDefaultValues } from "./StateInitialization";
import { FormSelectField } from "../../../../common/components/formSelectField/FormSelectField";
import { customSelectStyle } from "../../../../common/components/SelectCustomStyle";
import { DropdownStateType } from "../../recurringInvoiceTypes";
import { fetchGstSettings } from "../../../../home/gst/gstSlice";
import { gstRegistrationTypes } from "../../../../constants/constants";
import {
  joinForPayload,
  splitTransactionNumber,
} from "../../../../../helpers/prefixHelper";
import {
  currentUserSelector,
  paymentTermsSelector,
  setLoaderState,
} from "../../../../common/commonSlice";
import {
  RecurringInvoiceDefaultRef,
  RecurringInvoiceDefaultValues,
  RecurringInvoiceValues,
  ObjectType,
  PaymentTerms,
} from "../../../../../types";
import CustomerSelect from "../../../../common/components/CustomerSelect";
import useGst from "../../../../hooks/useGst";
import PaymentTermSelect from "../../../../common/components/PaymentTermSelect";
import SupplyStates from "../../../../common/components/SupplyStates";
import CustomDatepicker from "../../../../utils/atoms/datepicker";
import AlertModalPopup from "../../../../common/components/alertModalPopup/AlertModalPopup";
import moment from "moment";
import "./Invoice.css";

type Props = {
  defaultFormValues: RecurringInvoiceDefaultValues;
  invoiceFormValues: RecurringInvoiceValues;
  formErrors: any;
  handleCustomerChange: (e: any) => void;
  checkInvoiceNumberExist: (profileName: string) => void;
  organizationId: number;
  handleNewConversionDate: (value: string) => void;
  customFieldValue: ObjectType;
  customFieldRef: any;
  getCustomFieldData: (data: ObjectType) => void;
  setStateType?: any;
  fetchGstTaxList: (
    stateType: string,
    gst_registration_type: string | undefined
  ) => Promise<void>;
  setSupplyState?: any;
  initialStateType: string;
  setRepeatEvery: Dispatch<SetStateAction<DropdownStateType>>;
  setFrequency: Dispatch<SetStateAction<DropdownStateType>>;
  repeatEvery: DropdownStateType;
  frequency: DropdownStateType;
  setIsStateChanged?: any;
  gstRegistrationType?: string;
};

const DefaultDetails: ForwardRefRenderFunction<
  RecurringInvoiceDefaultRef,
  Props
> = (props, ref) => {
  const dispatch = useAppDispatch();
  const currentUserInfo = useAppSelector(currentUserSelector);
  const { editId } = useParams();
  const paymentTerms = useAppSelector(paymentTermsSelector);
  const subModulePermission = useSubModulePermission();

  const [defaultFormValues, setDefaultFormValues] =
    useState<RecurringInvoiceDefaultValues>(initialDefaultValues);
  const [invoiceNumberObj, setInvoiceNumberObj] = useState<ObjectType>({});
  const [
    placeOfSupplyUpdateModalTriggered,
    setPlaceOfSupplyUpdateModalTriggered,
  ] = useState<number>();

  const [showPlaceofSupplyFlag, setShowPlaceofSupplyFlag] = useState(true);
  const placeOfSupplyUpdateModalRef = useRef<any>();
  const { isGstOrg } = useGst();
  useImperativeHandle(
    ref,
    () => ({
      defaultData: defaultFormValues,
    }),
    [defaultFormValues, invoiceNumberObj]
  );
  useEffect(() => {
    if (!editId) dispatch(setLoaderState(false));
  }, []);

  const getGstSettings = async () => {
    let response = await dispatch(
      fetchGstSettings({
        orgId: currentUserInfo.organization_id,
      })
    );

    let data: any = response.payload;

    data?.gst_enabled
      ? setShowPlaceofSupplyFlag(true)
      : setShowPlaceofSupplyFlag(false);
  };

  useEffect(() => {
    getGstSettings();
  }, [currentUserInfo.organization_id, showPlaceofSupplyFlag]);

  useEffect(() => {
    setDefaultFormValues({ ...defaultFormValues, ...props.defaultFormValues });
    if (editId) {
      const transaction_obj = splitTransactionNumber(
        props.defaultFormValues.invoice_number
      );
      const invoice_obj = {
        invoice_prefix: transaction_obj["prefix"],
        profileName: transaction_obj["number"],
      };
      setInvoiceNumberObj(invoice_obj);
    }
  }, [props.defaultFormValues]);

  useEffect(() => {
    placeOfSupplyUpdateModalTriggered &&
      placeOfSupplyUpdateModalRef.current?.openAlertModal &&
      placeOfSupplyUpdateModalRef.current?.openAlertModal();
  }, [placeOfSupplyUpdateModalTriggered]);
  /**
   * For checking if the invoice number exist on prefix change
   */
  useEffect(() => {
    props.checkInvoiceNumberExist(joinForPayload(invoiceNumberObj));
  }, [invoiceNumberObj["invoice_prefix"]]);

  useEffect(() => {
    ($(".selectpicker") as any).selectpicker("refresh");
  }, [paymentTerms, defaultFormValues]);

  useEffect(() => {
    if (!editId) {
      paymentTerms.map((term: PaymentTerms, index) => {
        if (term.term_name == "Due on receipt") {
          setDefaultFormValues((values) => ({
            ...values,
            payment_term_id: term.id,
          }));
        }
      });
    }
  }, [paymentTerms]);

  function isFloat(value: any) {
    const res = value.split(".");
    if (res[1]) {
      return true;
    }
    return false;
  }

  /**
   * Set invoice default form values
   */
  const handleChange = (e: any) => {
    const { name, value, checked } = e.target;
    if (name === "payment_term_id" && value === "Custom") {
      setDefaultFormValues({ ...defaultFormValues, [name]: null });
    } else {
      if (name === "profile_name") {
        props.checkInvoiceNumberExist(value);
        setDefaultFormValues({
          ...defaultFormValues,
          [name]: value,
        });
      } else if (name === "reference_number") {
        if (value.length < 17) {
          setDefaultFormValues({ ...defaultFormValues, [name]: value });
        }
      } else if (name === "never_expire") {
        setDefaultFormValues({
          ...defaultFormValues,
          [name]: checked,
          ends_on: "",
        });
      } else if (name === "custom_repeat_count") {
        if ([!Number.isNaN(Number(value)), value !== "0"].every(Boolean))
          setDefaultFormValues({
            ...defaultFormValues,
            [name]: `${value.replaceAll(".", "").trim()}`,
          });
      } else {
        setDefaultFormValues({ ...defaultFormValues, [name]: value });
      }
    }
    if (name === "custom_repeat_count" && (value <= 0 || isFloat(value))) {
      setDefaultFormValues({ ...defaultFormValues, [name]: null });
    }
  };

  const handleCustomerChange = (e: {
    label: string;
    value: string | number;
  }) => {
    setDefaultFormValues((values) => ({
      ...values,
      customer_id: e.value.toString(),
      customer_name: e.label.trim(),
    }));
    props.handleCustomerChange(e);
  };

  const onStateChanged = async (
    name: string,
    option: { label: string; value: string }
  ) => {
    if (name === "repeat_every") {
      props.setRepeatEvery(option);
    }
    if (name === "custom_repeat_frequency") {
      props.setFrequency(option);
    }
    let newDefaultFormValues = { ...defaultFormValues, [name]: option.value };
    setDefaultFormValues(newDefaultFormValues);
    props.setSupplyState(option.value);
    props.setIsStateChanged(true);

    if (
      newDefaultFormValues?.place_of_supply &&
      currentUserInfo.organization_gst_state
    ) {
      let stateType = "";
      if (
        newDefaultFormValues?.place_of_supply ===
        currentUserInfo.organization_gst_state
      ) {
        if (props.initialStateType === "Inter State") {
          setPlaceOfSupplyUpdateModalTriggered(
            Math.floor(Math.random() * 1000)
          );
        }
        stateType = "Intra State";
        props.setStateType("Intra State");
      } else {
        if (props.initialStateType === "Intra State") {
          setPlaceOfSupplyUpdateModalTriggered(
            Math.floor(Math.random() * 1000)
          );
        }
        stateType = "Inter State";
        props.setStateType("Inter State");
      }
      props.fetchGstTaxList(stateType, props?.gstRegistrationType);
    } else {
      props.setStateType("");
    }
  };
  const handlePaymentTermChange = (e: any) => {
    if (e.value) {
      let value = e.value;
      setDefaultFormValues((values) => ({ ...values, payment_term_id: value }));
    }
  };

  const handleDate = (date: Date | null, type: string) => {
    if (date) {
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);
      const targetDate = new Date(date);
      targetDate.setHours(0, 0, 0, 0);
      if (date?.toString() === "Invalid Date" || currentDate > targetDate) {
        setDefaultFormValues((values) => ({
          ...values,
          [type]: "Invalid date",
        }));
      } else {
        const dateFormated = getFormatedDate(date);
        if (moment(dateFormated, "YYYY-MM-DD", true).isValid()) {
          let neverExpiresValue = defaultFormValues.never_expire;
          if (type === "ends_on") {
            neverExpiresValue = false;
          }
          setDefaultFormValues((values) => ({
            ...values,
            [type]: dateFormated,
            never_expire: neverExpiresValue,
          }));
          props.handleNewConversionDate(dateFormated);
        } else {
          setDefaultFormValues((values) => ({
            ...values,
            [type]: "Invalid date",
          }));
        }
      }
    } else if (type === "start_on" && date === null) {
      setDefaultFormValues((values) => ({
        ...values,
        [type]: "Empty date",
      }));
    } else if (type === "ends_on" && date === null) {
      setDefaultFormValues((values) => ({
        ...values,
        [type]: "",
      }));
    }
  };

  const checkCustomerSelectIsDisabled = () => {
    if (editId) {
      return !props.defaultFormValues.is_mutable;
    }
    return false;
  };

  return (
    <section className="form-top-section">
      <div className="row custom-row">
        <div className="col-12 col-lg-4 mw-325">
          <div className="link-label-grouped">
            <label htmlFor="customer" className="required">
              Customer Name
            </label>
            <AddCustomerModal />
          </div>
          <div>
            <CustomerSelect
              customerId={defaultFormValues.customer_id}
              errors={props.formErrors.customer_name}
              onChange={handleCustomerChange}
              isDisabled={checkCustomerSelectIsDisabled()}
            />
            <span className="error">{props.formErrors.customer_name}</span>
          </div>
        </div>
        <FormInputField
          type="text"
          name="profile_name"
          id="c_display_name"
          className={
            props.formErrors.profile_name
              ? "form-control error"
              : "form-control"
          }
          label="Profile Name"
          placeholder="Enter profile name"
          maxLength={16}
          value={defaultFormValues.profile_name}
          onChange={handleChange}
          isRequired={true}
          error={props.formErrors.profile_name}
        />
      </div>
      <div className="row custom-row">
        <FormInputField
          type="text"
          name="reference_number"
          id="ref_no"
          className={
            props.formErrors.reference_number
              ? "form-control error"
              : "form-control"
          }
          label="Reference Number"
          placeholder="Enter reference number"
          maxLength={16}
          value={defaultFormValues.reference_number}
          onChange={handleChange}
          error={props.formErrors.reference_number}
        />
        <div className="col-12 col-lg-4 mw-325">
          <FormSelectField
            name="repeat_every"
            id="repeat_every"
            label="Repeat Every"
            options={[
              { label: "Week", value: "week" },
              { label: "Month", value: "month" },
              { label: "Year", value: "year" },
              { label: "Custom", value: "custom" },
            ]}
            wrapperClass="mw-325"
            className="state-select custom-select"
            value={props.repeatEvery}
            onChange={(option: any) => {
              onStateChanged("repeat_every", option);
            }}
            styles={customSelectStyle}
            placeholder={"Repeat Every"}
            isSearchable={true}
            isDisabled={false}
            error={props.formErrors.repeat_every}
            isRequired={true}
            isOnlyInRow={false}
          />
        </div>
      </div>
      {defaultFormValues.repeat_every === "custom" ? (
        <div className="row custom-row">
          <div className="col-12 col-lg-4 mw-325 frequency-container">
            <FormSelectField
              name="custom_repeat_frequency"
              id="custom_repeat_frequency"
              label="Frequency"
              options={[
                { label: "Day(s)", value: "days" },
                { label: "Week(s)", value: "weeks" },
                { label: "Month(s)", value: "months" },
                { label: "Year(s)", value: "years" },
              ]}
              wrapperClass="mw-325"
              className="state-select custom-select"
              value={props.frequency}
              onChange={(option: any) => {
                onStateChanged("custom_repeat_frequency", option);
              }}
              styles={customSelectStyle}
              placeholder={"Repeat Every"}
              isSearchable={true}
              isDisabled={false}
              error={props.formErrors.custom_repeat_frequency}
              isRequired={true}
              isOnlyInRow={false}
            />
            <div className="width-35">
              <FormInputField
                type="text"
                name="custom_repeat_count"
                id="custom_repeat_count"
                className={
                  props.formErrors.custom_repeat_count
                    ? "form-control error custom_repeat_count_width_fix"
                    : "form-control custom_repeat_count_width_fix"
                }
                label="."
                placeholder=""
                maxLength={9}
                value={defaultFormValues.custom_repeat_count}
                onChange={handleChange}
                isRequired={false}
                error={props.formErrors.custom_repeat_count}
              />
            </div>
          </div>
          <div className="col-12 col-lg-4 mw-325"></div>
        </div>
      ) : null}
      <div className="row custom-row">
        <div className="col-12 col-lg-4 mw-325">
          <label htmlFor="due_date">Start On</label>
          <div id="date-two" className="date-selector-wrapper">
            <CustomDatepicker
              date={
                defaultFormValues.start_on
                  ? new Date(defaultFormValues.start_on)
                  : null
              }
              handleDate={handleDate}
              type="start_on"
              id="start_on"
              error={props.formErrors.start_on ? "error" : ""}
              zIndex={0}
              enableFutureDate={true}
              minDate={new Date()}
            />
          </div>
          <span className="error">{props.formErrors.start_on}</span>
        </div>
        <div className="col-12 col-lg-4 mw-325">
          <div className="link-label-grouped">
            <label htmlFor="terms">End On</label>
            {/* {subModulePermission.includes("Payments Terms") ? (
                <PaymentTermsModal />
              ) : null} */}
            <div className="configure-payment-term-modal w-100">
              <div className="reusable-modal-btn">
                <span className="payment-term-modal-btn">
                  <span className="check-container">
                    <input
                      name="never_expire"
                      checked={defaultFormValues.never_expire}
                      type="checkbox"
                      onChange={handleChange}
                      className="never-expires"
                    />
                    Never Expires
                  </span>
                </span>
              </div>
            </div>
          </div>
          <div id="date-two" className="date-selector-wrapper">
            <CustomDatepicker
              date={
                defaultFormValues.ends_on
                  ? new Date(defaultFormValues.ends_on)
                  : null
              }
              handleDate={handleDate}
              type="ends_on"
              id="ends_on"
              error={props.formErrors.ends_on ? "error" : ""}
              zIndex={0}
              enableFutureDate={true}
              minDate={new Date()}
            />
          </div>
          <span className="error">{props.formErrors.ends_on}</span>
        </div>
      </div>
      <div className="row custom-row">
        <div className="col-12 col-lg-4 mw-325">
          <div className="link-label-grouped">
            <label htmlFor="terms">Terms</label>
            {subModulePermission.includes("Payments Terms") ? (
              <PaymentTermsModal />
            ) : null}
          </div>
          <PaymentTermSelect
            paymentTermId={defaultFormValues.payment_term_id}
            haveCustom={false}
            isCustom={false}
            onChange={(e: any) => {
              handlePaymentTermChange(e);
            }}
          />
        </div>
        <div className="col-12 col-lg-4 mw-325">
          {isGstOrg &&
            props.gstRegistrationType !== gstRegistrationTypes.overseas && (
              <SupplyStates
                name="place_of_supply"
                id="place_of_supply"
                label="Place of Supply"
                value={
                  defaultFormValues?.place_of_supply
                    ? {
                        value: defaultFormValues?.place_of_supply,
                        label: defaultFormValues?.place_of_supply,
                      }
                    : ""
                }
                onChange={(option: any) => {
                  onStateChanged("place_of_supply", option);
                }}
                error={props.formErrors.place_of_supply}
              />
            )}
        </div>
      </div>
      <div>
        <FormInputFieldsInRows
          getFieldValues={props.getCustomFieldData}
          customFieldsArray={[]}
          initialValues={props.customFieldValue}
          ref={props.customFieldRef}
          editId={editId ? Number(editId) : null}
        />
      </div>
      <AlertModalPopup
        message="We will update the taxes applied to the items in this transaction as you've changed the place of supply"
        modalTitle="Alert!"
        isCloseButtonEnabled={true}
        isSubmitEnabled={false}
        openRef={placeOfSupplyUpdateModalRef}
      />
    </section>
  );
};

export default React.memo(forwardRef(DefaultDetails));
