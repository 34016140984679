import { useEffect, useMemo, useRef, useState } from "react";
import { useAppSelector } from "../../../../../app/hooks";
import { joinTransactionNumber } from "../../../../../helpers/prefixHelper";
import {
  InvoiceDetailValues,
  SubscriptionDetailHeaderValues,
  SubscriptionDetailValues,
} from "../../../../../types";
import { orgCurrencyListSelector } from "../../../../common/commonSlice";
import CompanyDetails from "../../../../common/components/detailsPage/CompanyDeatails";
import DetailTotalSection from "../../../../common/components/detailsPage/DeatailsTotalSection";
import TaxColumn from "../../../../common/components/detailsPage/TaxColumn";
import FileList from "../../../../common/components/FileList";
import JournelView from "../../../../common/components/JournelView";
import {
  NumberFormat,
  zeroDisplayFormat,
} from "../../../../../helpers/numberFormatHelper";
import DetailsTableContainer from "../../../../common/components/detailsPage/DetailsTableContainer";
import { RowWiseFieldView } from "../../../../common/components/rowWiseFieldsView/rowWiseFieldsView";
import CreditNoteAndPaymentReceivedDetails from "../CNandPRDetails/CNandPRDetails";
import { usePermissionHandler } from "../../../../hooks/usePermissionHandler";
import ApplyCredits from "./ApplyCredits";
import {
  tdsApplyLevel,
  noAccessErrorCodes,
} from "../../../../constants/constants";
import useCommonData from "../../../../hooks/useCommon";
import useGst from "../../../../hooks/useGst";
import { decimalPlaceOfCurrency } from "../../../../../helpers/decimalPlaceHelper";
import { ApplyCreditsFormModal } from "./ApplyCredits/ApplyCreditsFormModal";
import DetailsTableContainerSubscription from "../../../../common/components/detailsPage/DetailsTableContainerSubscription";
import { subscriptionDetailsInitialValues } from "../../../subscription/SubscriptionStateInitialization";
import { setLoaderState } from "../../../../common/commonSlice";
import { subscriptionDataForInvoice } from "../../../subscription/subscriptionSlice";
import { setErrorID } from "../../../../../appSlice";
import "./invoicePreviewBodySubscription.css";

type Props = {
  invoiceData: InvoiceDetailValues;
  reRenderFlag: number;
  invoiceDetail: () => void;
  type?: string;
  editInvoiceId?: string;
};

const InvoicePreviewBodySubscription: React.FC<Props> = ({
  invoiceData,
  reRenderFlag,
  invoiceDetail,
  type,
  editInvoiceId,
}) => {
  let taxTypes = Object.entries(invoiceData.total_details.taxes);
  const { currentUserInfo, orgCurrencyList, dispatch, navigate } =
    useCommonData();
  const { creditNoteRolePermission } = usePermissionHandler();
  const transactionRef = useRef<{ fetchCNPRList: () => void }>(null);
  const creditApplyRef = useRef<{ getApplyCreditList: () => void }>(null);
  const { isGstOrg } = useGst();
  const [overviewSubscriptionData, setOverviewSubscriptionData] =
    useState<SubscriptionDetailValues>(subscriptionDetailsInitialValues);
  /**
   * Format date from YYYY-MM-DD to DD-MM-YYYY
   */
  const dateFormatHandler = (date: string) => {
    if (!date) {
      return "";
    }
    let newDate = date.split("-").reverse().join("-");
    return newDate;
  };

  useEffect(() => {
    if (editInvoiceId) {
      subscriptionDetail();
    }
  }, [currentUserInfo.organization_id]);

  const subscriptionDetail = async () => {
    dispatch(setLoaderState(true));
    const responseAction = await dispatch(
      subscriptionDataForInvoice({
        invoiceId: Number(editInvoiceId),
        orgId: currentUserInfo.organization_id,
      })
    );
    if (responseAction.payload) {
      setTimeout(function () {
        dispatch(setLoaderState(false));
      }, 500);
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        setOverviewSubscriptionData(response);
      } else {
        dispatch(setErrorID(noAccessErrorCodes.invoiceDetail));
        navigate("/recurring-inv");
      }
    }
  };

  /**
   * set table column headings for plan and addon
   */

  const columnsForPlanAddon = useMemo(() => {
    const columns = [
      {
        Header: "Description",
        accessor: "show.description",
        className: "description",
        Cell: ({ cell: { value } }: any) => {
          return <span className="item-bold">{value}</span>;
        },
      },
      // Conditional HSN/SAC Code column
      ...(isGstOrg
        ? [
            {
              Header: "Hsn/sac code",
              accessor: "show.hsnCode",
              className: "hsncode text-nowrap",
            },
          ]
        : []),
      {
        Header: "Account",
        accessor: "show.account",
        className: "account",
      },
      {
        Header: "Quantity",
        accessor: "show.quantity",
        className: "quantity text-nowrap",
      },
      {
        Header: "Rate",
        accessor: "show.rate",
        className: "rate text-nowrap",
      },
      {
        Header: "Discount",
        accessor: "show.discount",
        className: "discount text-nowrap left-align",
        Cell: ({ cell: { value } }: any) => {
          let discAccount = value?.discountAccount
            ? value.discountAccount.split(" (")
            : null;
          return (
            <>
              <span className="amt">{value.discount}</span>
              <span className="acnt">
                {discAccount && discAccount.length > 0 ? discAccount[0] : ""}{" "}
                <br />{" "}
                {discAccount && discAccount.length === 2
                  ? "(" + discAccount[1]
                  : ""}
              </span>
            </>
          );
        },
      },
      {
        Header: "Tax",
        accessor: "show.tax",
        className: "tax text-nowrap text-align-left",
        Cell: ({ cell: { value } }: any) => {
          return (
            <TaxColumn
              values={value}
              currencyCode={invoiceData.currency_code}
            />
          );
        },
      },
      {
        Header: "TDS",
        accessor:
          invoiceData.tds_level === tdsApplyLevel.LINE_ITEM_LEVEL
            ? "show.item"
            : "TDS",
        className: "tds text-nowrap text-align-left",
        Cell: ({ cell: { value } }: any) => {
          return (
            <>
              <span className="amt">
                {invoiceData.currency_code}{" "}
                {NumberFormat(
                  Number(value.tds_total),
                  invoiceData.currency_code,
                  orgCurrencyList
                )}
              </span>
              <span className="actual-value d-block w-100 clear-both">
                {value.tds_name}{" "}
                {value.default_tds_percentage
                  ? "(" + value.default_tds_percentage + "%)"
                  : ""}
              </span>
              {value.tds_percentage !== 0 &&
              value.tds_percentage !== value.default_tds_percentage ? (
                <span className="current-value  d-block w-100 clear-both">
                  Updated Rate {`(${value.tds_percentage}%)`}
                </span>
              ) : null}
            </>
          );
        },
      },
      {
        Header: "Taxable Amount",
        accessor: "show.taxableAmount",
        className: "taxableAmount text-nowrap text-align-right",
      },
    ];

    return columns;
  }, [invoiceData.currency_code, isGstOrg]);

  function convertToNormalText(str: string) {
    if (!str) return "";
    let words = str.toLowerCase().split("_");
    return words.join(" ");
  }

  function formatFrequency(frequency: string) {
    if (!frequency) return "";
    let formattedStr = frequency
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
    return formattedStr;
  }

  return (
    <div className="invoice-preview-body-sub-main-cont">
      <div
        id="invoice"
        className="invoice-preview transaction-preview invoice-preview-subscription-body"
      >
        <div className="preview-top-butons w-100 d-flex justify-content-end">
          {invoiceData.organization_id === currentUserInfo.organization_id &&
          creditNoteRolePermission.includes("Read") &&
          invoiceData.total_details?.balance_due &&
          Number(invoiceData?.total_details?.balance_due) > 0 &&
          invoiceData.excess_credit &&
          invoiceData.excess_credit > 0 ? (
            <div>
              <div className="credits-available credits-available-label">
                Credits Available{" "}
                <span className="available-amount">
                  {NumberFormat(
                    Number(invoiceData.excess_credit),
                    invoiceData.currency_code,
                    orgCurrencyList
                  )}
                </span>
              </div>
              <ApplyCreditsFormModal
                invoiceData={invoiceData}
                refreshInvoiceDetails={() => {
                  invoiceDetail();
                  if (transactionRef.current)
                    transactionRef.current.fetchCNPRList();
                }}
                isVoid={false}
              />
            </div>
          ) : null}
        </div>
        <CreditNoteAndPaymentReceivedDetails
          invoiceId={Number(invoiceData.id)}
          invoiceDetail={() => {
            invoiceDetail();
            if (creditApplyRef.current)
              creditApplyRef.current.getApplyCreditList();
          }}
          ref={transactionRef}
          orgId={Number(invoiceData.organization_id)}
        />
        <div className="invoice-header d-flex justify-content-between">
          <CompanyDetails organizationData={invoiceData.organization_details} />
          <div className="invoice-details">
            <div className="label-invoice">Invoice</div>
            <div className="invoice-number-label">Invoice Number</div>
            <div className="invoice-number">
              {joinTransactionNumber(invoiceData.invoice_number) || ""}
            </div>
            {invoiceData.reference_number ? (
              <>
                <div className="invoice-number-label">Reference Number</div>
                <div className="invoice-number">
                  {invoiceData.reference_number}
                </div>
              </>
            ) : null}
            <div className="invoice-number-label">Balance Due</div>
            <div className="invoice-number mb-0">
              {invoiceData.currency_code}{" "}
              {invoiceData.total_details.balance_due
                ? NumberFormat(
                    Number(invoiceData.total_details.balance_due),
                    invoiceData.currency_code,
                    orgCurrencyList
                  )
                : zeroDisplayFormat(
                    decimalPlaceOfCurrency(
                      invoiceData.currency_code
                        ? invoiceData.currency_code
                        : currentUserInfo.currency_code,
                      orgCurrencyList
                    )
                  )}
            </div>
          </div>
        </div>
        <div className="bill-details-section d-flex justify-content-between">
          <div className="billed-to">
            <span className="bill-details-header">Billed To</span>
            <div className="company-address pt-3">
              {invoiceData.address_details.length > 0 ? (
                invoiceData.address_details.map((data, index) => {
                  if (data.is_billing) {
                    return (
                      <div key={index}>
                        <span>
                          {invoiceData.customer_name
                            ? invoiceData.customer_name
                            : ""}
                        </span>
                        {data.address && <br />}
                        <span>{data.address ? data.address : ""}</span>
                        {(data.city || data.state_name) && <br />}
                        <span>
                          {data.city ? data.city : ""}
                          {data.city === null || data.city === "" ? "" : ", "}
                          {data.state_name ? data.state_name : ""}
                        </span>
                        {(data.country_name || data.zip_code) && <br />}
                        <span>
                          {data.country_name ? data.country_name : ""}
                          {data.country_name === null ||
                          data.country_name === "" ||
                          data.zip_code === null
                            ? ""
                            : "-"}
                          {data.zip_code ? data.zip_code : ""}
                        </span>
                        {invoiceData.customer_gstin && (
                          <>
                            <br />
                            <span className="gst-info">
                              GSTIN:{" " + invoiceData.customer_gstin}
                            </span>
                          </>
                        )}
                      </div>
                    );
                  }
                })
              ) : (
                <div>
                  <span>
                    {invoiceData.customer_name ? invoiceData.customer_name : ""}
                  </span>
                  {invoiceData.customer_gstin && (
                    <>
                      <br />
                      <span className="gst-info">
                        GSTIN:{" " + invoiceData.customer_gstin}
                      </span>
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="ship-to">
            <span className="bill-details-header">Ship To</span>
            <div className="company-address pt-3">
              {invoiceData.address_details.length > 0 ? (
                invoiceData.address_details.map((data, index) => {
                  if (data.is_shipping) {
                    return (
                      <div key={index}>
                        <span>
                          {invoiceData.customer_name
                            ? invoiceData.customer_name
                            : ""}
                        </span>
                        {data.address && <br />}
                        <span>{data.address}</span>
                        {(data.city || data.state_name) && <br />}
                        <span>
                          {data.city}
                          {data.city === null || data.city === "" ? "" : ", "}
                          {data.state_name}
                        </span>
                        {(data.country_name || data.zip_code) && <br />}
                        <span>
                          {data.country_name}
                          {data.country_name === null ||
                          data.country_name === "" ||
                          data.zip_code === null
                            ? ""
                            : "-"}
                          {data.zip_code}
                        </span>
                        {/* {(data.country_name || data.zip_code) && <br />} */}
                        {invoiceData.customer_gstin && (
                          <>
                            <br />
                            <span className="gst-info">
                              GSTIN:{" " + invoiceData.customer_gstin}
                            </span>
                          </>
                        )}
                      </div>
                    );
                  }
                })
              ) : (
                <div>
                  <span>
                    {invoiceData.customer_name ? invoiceData.customer_name : ""}
                  </span>
                  {invoiceData.customer_gstin && (
                    <>
                      <br />
                      <span className="gst-info">
                        GSTIN:{" " + invoiceData.customer_gstin}
                      </span>
                    </>
                  )}
                </div>
              )}
            </div>
          </div>

          <div className="inv-date-wrap">
            <div className="inv-date">
              <span className="bill-details-header">Invoice Date</span>
              <div>{dateFormatHandler(invoiceData.invoice_date)}</div>
            </div>
            <div className="inv-date">
              <span className="bill-details-header">Due Date</span>
              <div>{dateFormatHandler(invoiceData.due_date)}</div>
            </div>
          </div>
          <div className="payment-terms">
            <span className="bill-details-header">Payment Terms</span>
            <div>
              {invoiceData.payment_term_id
                ? invoiceData.payment_term
                : "Custom"}
            </div>
          </div>
          {invoiceData?.place_of_supply !== null && (
            <div className="invoice-supply-wrap">
              <div className="invoice-supply m-0">
                <span className="invoice-details-header">Place of supply</span>
                <div>{invoiceData.place_of_supply}</div>
              </div>
            </div>
          )}
        </div>
        <RowWiseFieldView
          mapObject={invoiceData.custom_fields}
          module="Invoices"
        />
        <div>
          <span>
            <h2 className="mb-0 subscription-details-plan-addon-coupon-heading-invoice">
              Plan, Addon & Coupon Details
            </h2>
          </span>
        </div>
        <div className="table-section">
          <div className="table-wrap subscription-invoice-details">
            {overviewSubscriptionData.plan_details &&
              overviewSubscriptionData.plan_details.length > 0 &&
              overviewSubscriptionData?.plan_details?.map((plan) => {
                return (
                  <>
                    <div className="plan-description">
                      <div style={{ fontSize: "larger", marginBottom: "10px" }}>
                        Plans
                      </div>
                      <h6 className="item-bold">{plan.plan_name}</h6>
                      <div className="plan-tag">
                        <span>
                          Frequency: {formatFrequency(plan.frequency)} | Pricing
                          model: {formatFrequency(plan.pricing_model)}
                        </span>
                      </div>
                      <div>
                        <span style={{ color: "#747d84" }}>
                          Invoicing period: {plan.invoicing_period}
                        </span>
                      </div>
                      {!!plan.description ? (
                        <div>
                          <span style={{ color: "#747d84" }}>
                            Description: {plan.description}
                          </span>
                        </div>
                      ) : null}
                    </div>
                    <DetailsTableContainerSubscription
                      columns={columnsForPlanAddon}
                      data={plan.items}
                      currency_code={invoiceData.currency_code}
                      haveColumnClassName={true}
                      className="table inv-preview-table subscription-data-view-table"
                      hiddenColumns={["TDS"]}
                      pricingModel={plan.pricing_model}
                      component="invoice"
                    />
                  </>
                );
              })}
            {overviewSubscriptionData.addon_details &&
              overviewSubscriptionData.addon_details.length > 0 && (
                <div
                  style={{
                    fontSize: "larger",
                    paddingLeft: "23px",
                    paddingTop: "19px",
                    marginBottom: "-13px",
                  }}
                >
                  Addons
                </div>
              )}
            {overviewSubscriptionData.addon_details &&
              overviewSubscriptionData.addon_details.length > 0 &&
              overviewSubscriptionData?.addon_details?.map((addon) => {
                return (
                  <>
                    <div className="plan-description">
                      <h6 className="item-bold">{addon.addon_name}</h6>
                      <div className="plan-tag">
                        <span>
                          Frequency: {formatFrequency(addon.frequency)} |
                          Pricing model: {formatFrequency(addon.pricing_model)}
                        </span>
                      </div>
                      <div>
                        <span style={{ color: "#747d84" }}>
                          Invoicing period: {addon.invoicing_period}
                        </span>
                      </div>
                      {!!addon.description ? (
                        <div>
                          <span style={{ color: "#747d84" }}>
                            Description: {addon.description}
                          </span>
                        </div>
                      ) : null}
                    </div>
                    <DetailsTableContainerSubscription
                      columns={columnsForPlanAddon}
                      data={addon.items}
                      currency_code={invoiceData.currency_code}
                      haveColumnClassName={true}
                      className="table inv-preview-table subscription-data-view-table"
                      hiddenColumns={["TDS"]}
                      pricingModel={addon.pricing_model}
                      component="invoice"
                    />
                  </>
                );
              })}
            {overviewSubscriptionData.coupon_details &&
              overviewSubscriptionData.coupon_details.length > 0 && (
                <div
                  style={{
                    fontSize: "larger",
                    paddingLeft: "23px",
                    paddingTop: "19px",
                    marginBottom: "-13px",
                  }}
                >
                  Coupons
                </div>
              )}
            {overviewSubscriptionData.coupon_details &&
              overviewSubscriptionData.coupon_details.length > 0 &&
              overviewSubscriptionData?.coupon_details?.map((coupon) => {
                return (
                  <>
                    <div className="plan-description coupon-description-container">
                      <div>
                        <h6 className="item-bold">{coupon.name}</h6>
                        <div className="plan-tag">
                          <span>
                            Applicable {convertToNormalText(coupon.duration)}
                          </span>
                        </div>
                      </div>
                      <div className="coupon-discount-amount-container">
                        {coupon.discount}
                      </div>
                    </div>
                  </>
                );
              })}
          </div>
          <div className="d-flex justify-content-between pt-3 mt-1 w-100 table-bottom-sect-wrap">
            <div className="thankyou-note w-50 d-flex align-items-end">
              {invoiceData?.customer_notes && (
                <div className="note-container">
                  <span className="label">Customer Notes</span>
                  <p>{invoiceData.customer_notes}</p>
                </div>
              )}
            </div>
            <DetailTotalSection
              currencyCode={invoiceData.currency_code}
              totalTax={invoiceData.total_details.tax_amount}
              paymentLabel="Payment received"
              payment={invoiceData.total_details.payment_made}
              taxTypes={taxTypes}
              subTotal={invoiceData.total_details.total_taxable_amount}
              total={invoiceData.total_details.total}
              balanceLabel={"Balance Due"}
              balanceAmount={
                invoiceData.total_details.balance_due
                  ? invoiceData.total_details.balance_due
                  : zeroDisplayFormat(
                      decimalPlaceOfCurrency(
                        invoiceData.currency_code
                          ? invoiceData.currency_code
                          : currentUserInfo.currency_code,
                        orgCurrencyList
                      )
                    )
              }
              tdsItems={{
                tdsAmount: invoiceData.total_details.transaction_tds_amount
                  ? invoiceData.total_details.transaction_tds_amount
                  : 0,
                tdsName: invoiceData.tds_name,
                tdsPercentage: invoiceData.tds_percentage,
                defaultTdsPercentage: invoiceData.default_tds_percentage,
              }}
              tcsItems={{
                tcsAmount: invoiceData.tcs_total,
                tcsName: invoiceData.tcs_name,
                tcsPercentage: invoiceData.tcs_percentage,
              }}
              roundOffValue={invoiceData.total_details.roundoff_amount}
              roundoffEnabled={invoiceData.roundoff_enabled}
              couponRequired={true}
              coupon_amount={invoiceData.total_details?.coupon_amount}
            >
              <></>
            </DetailTotalSection>
          </div>
        </div>
        {invoiceData.sales_invoice_files.length ? (
          <FileList files={invoiceData.sales_invoice_files} />
        ) : null}
        <JournelView
          title="Journal - Invoice"
          transactionType="Invoices"
          transactionId={Number(invoiceData.id)}
          reRenderFlag={reRenderFlag}
        />
      </div>
    </div>
  );
};
export default InvoicePreviewBodySubscription;
