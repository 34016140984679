import { FormikProps } from "formik";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppSelector } from "../../../../../../app/hooks";
import { AccessInfoImg, AddTagIcon } from "../../../../../../assets/images";
import { decimalPlaceOfCurrency } from "../../../../../../helpers/decimalPlaceHelper";
import { splitNumberByDecimalPoint } from "../../../../../../helpers/helper";
import { zeroDisplayFormat } from "../../../../../../helpers/numberFormatHelper";
import { getRoundOffAmount } from "../../../../../../helpers/roundOffHelper";
import {
  CurrencyList,
  JVItemValues,
  JournalItemValues,
  ObjectType,
} from "../../../../../../types";
import useCommonData from "../../../../../hooks/useCommon";
import { accountsGlCodeSelector } from "../../../../chartOfAccounts/coaSlice";
import {
  customerDropdownList,
  customerListSelector,
} from "../../../../customerManagement/customerSlice";
import {
  vendorDropdownList,
  vendorListSelector,
} from "../../../../vendorManagement/vendorSlice";
import { initialItemValues } from "../../helpers/StateInitialization";
import { getAmountCalculation } from "../../../systemVoucherSlice";
import JournalItem from "./JournalItem";
import "./JournalItemDetails.css";
import JournalItemTotal from "./JournalTotalDetails";
import { useJVExchange } from "../../hooks/useJVExchange";

type Props = {
  itemFormValues: JVItemValues[];
  currencyCode: string;
  currencyId: number;
  organizationId: number;
  itemFormErrors: JVItemValues[];
  formErrors: ObjectType;
  conversionDate?: string;
  handleSetAccountAmount?: (value: boolean) => void;
  getExchangeRateValueUpdates?: (values: CurrencyList) => void;
  setConversionId?: (id: number) => void;
  jvExchangeRateValues?: CurrencyList;
  currencyTriggerFlag?: number;
  dateTriggerFlag?: number;
  formik: FormikProps<any>;
};
const SystemVoucherItemDetails = (props: Props, ref: any) => {
  const { dispatch, currentUserInfo, orgCurrencyList } = useCommonData();
  const { changeItemListCustomExchanges } = useJVExchange();
  const accountsGlCode = useAppSelector(accountsGlCodeSelector);
  const { editId } = useParams();
  //   const [itemFormValues, setItemFormValues] = useState<JournalItemValues[]>([
  //     initialItemValues,
  //   ]);
  const [deleteItems, setDeleteItems] = useState<{ itemId: number }[]>([]);
  const [removeItemContainer, setRemoveItemContainer] = useState(false);
  const [toCurrencyId, setToCurrencyId] = useState(1);
  const [toCurrencyCode, setToCurrencyCode] = useState("");
  const [convertedAmountDict, setConvertedAmountDict] = useState<{
    [key: string]: { debit: number; credit: number };
  }>({});
  const [conversions, setConversions] = useState<
    {
      currencyId: number;
      toCurrencyId: number;
      index: number;
      conversionId: number | null | undefined;
    }[]
  >([]);
  const [totalAmountDebit, setTotalAmountDebit] = useState(
    zeroDisplayFormat(
      decimalPlaceOfCurrency(
        props.currencyCode ? props.currencyCode : currentUserInfo.currency_code,
        orgCurrencyList
      )
    )
  );

  const customerList = useAppSelector(customerListSelector);
  const vendorsList = useAppSelector(vendorListSelector);
  const [totalAmountCredit, setTotalAmountCredit] = useState(
    zeroDisplayFormat(
      decimalPlaceOfCurrency(
        props.currencyCode ? props.currencyCode : currentUserInfo.currency_code,
        orgCurrencyList
      )
    )
  );
  const [differenceDebit, setDifferenceDebit] = useState("");
  const [differenceCredit, setDifferenceCredit] = useState(
    zeroDisplayFormat(
      decimalPlaceOfCurrency(
        props.currencyCode ? props.currencyCode : currentUserInfo.currency_code,
        orgCurrencyList
      )
    )
  );
  const [accountAmountExist, setAccountAmountExist] = useState(false);
  const [itemFormErrors, setItemFormErrors] = useState<ObjectType[]>(
    props.itemFormErrors
  );

  useEffect(() => {
    if (!editId) {
      addItemContainer();
    }
  }, []);
  useEffect(() => {
    if (editId && !props.formik.dirty) {
      changeItemListCustomExchanges(props.formik);
    }
  }, [editId, props.formik.values.item_details]);

  useEffect(() => {
    setItemFormErrors([...props.itemFormErrors]);
  }, [props.itemFormErrors]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    calculateJournalAmount(signal);
    return () => {
      controller.abort();
    };
  }, [props.formik.values.item_details]);

  useEffect(() => {
    getSearchVendorsList();
    getSearchCustomersList();
  }, [props.organizationId]);

  useEffect(() => {
    const formData = [...props.formik.values.item_details];
    const formLength = formData.filter(
      (item) => item.account_id || item.credit || item.debit
    ).length;
    if (props.currencyCode && formLength) {
      const updatedDecimals = decimalPlaceOfCurrency(
        props.currencyCode,
        orgCurrencyList
      );
      for (let item of formData) {
        item.debit = getRoundOffAmount(Number(item.debit), updatedDecimals);
        item.credit = getRoundOffAmount(Number(item.credit), updatedDecimals);
      }
      props.formik.setFieldValue("item_details", formData);
    }
  }, [props.currencyCode]);

  /**
   * Append new item container
   */
  const addItemContainer = () => {
    const itemObject = initialItemValues;
    // setItemFormValues((itemFormValues) => [...itemFormValues, itemObject]);
    props.formik.setFieldValue("item_details", [
      ...props.formik.values.item_details,
      itemObject,
    ]);
  };
  /**
   * Delete item container
   */
  const deleteItemContainer = async (index: number) => {
    Array.isArray(props.formik.values.item_details) &&
      props.formik.values.item_details.map(
        (items: JournalItemValues, indices: number) => {
          if (indices === index) {
            setDeleteItems((prevItems) => [
              ...prevItems,
              { itemId: Number(items?.id) },
            ]);
            props.formik.setFieldValue("deleted_items", [
              ...deleteItems,
              { itemId: Number(items?.id) },
            ]);
          }
        }
      );

    const items = [...props.formik.values.item_details];
    const itemId: any = items[index].id;
    items.splice(index, 1);
    props.formik.setFieldValue("item_details", items);
  };
  /**
   * Function to round off input elements after out of focus.
   */
  const handleItemOnBlur = (name: string, amount: number, index: number) => {
    const items = [...props.formik.values.item_details];
    const [, fractionalPart] = splitNumberByDecimalPoint(amount);
    let roundedAmount: number = amount;
    const decimals = decimalPlaceOfCurrency(
      props.currencyCode,
      orgCurrencyList
    );
    if (amount.toString().includes(".")) {
      if (
        (name === "debit" || name === "credit") &&
        fractionalPart.length > decimals
      ) {
        roundedAmount = getRoundOffAmount(amount, decimals);
        items[index] = { ...items[index], [name]: roundedAmount };
        props.formik.setFieldValue("item_details", items);
      }
    }
  };

  const handleItemAccountChange = (
    accountId: number,
    index: number,
    accountName: string
  ) => {
    if (accountId) {
      const items = [...props.formik.values.item_details];
      items[index] = { ...items[index], account_id: accountId };
      items[index] = { ...items[index], account_name: accountName };

      items[index] = {
        ...items[index],
        contact_id: "",
        contact_name: "",
        contact_type: "",
      };
      let errors: any = [...itemFormErrors];
      if (errors[index] && errors[index].account_id) {
        if (Object.keys(errors[index]).length <= 1) {
          delete errors[index];
        } else {
          delete errors[index].account_id;
        }
      }
      // }
      if (errors[index] && (errors[index].customer || errors[index].vendor)) {
        delete errors[index]?.customer;
        delete errors[index]?.vendor;
      }
      props.formik.setFieldValue("item_details", items);
      setItemFormErrors(errors);
    }
  };

  //Handling the account dropdown show properly by removing the overflow element
  //Adding the table overflow back on account dropdown hide
  const responsiveTableDropdown = () => {
    let resizeFlag: boolean = false;
    $(".jv-item-container .item-table-wrapper").on(
      "show.bs.dropdown",
      function () {
        setTimeout(() => {
          $(".jv-item-container .item-table-wrapper").css({
            overflow: "initial",
          });
        }, 5);

        // $(".jv-item-container table").css("margin-left", "-" + accountDropLeft + "px");
      }
    );

    $(".jv-item-container .item-table-wrapper").on(
      "hide.bs.dropdown",
      function () {
        $(".jv-item-container .item-table-wrapper").css({
          "overflow-x": "auto",
          "overflow-y": "hidden",
        });
        // $(".jv-item-container table").css("margin-left", 0);
      }
    );
    window.addEventListener("resize", () => {
      if (!resizeFlag) {
        resizeFlag = true;
        ($(".jv-item-container .item-table-wrapper") as any).dropdown("hide");
        setTimeout(() => {
          resizeFlag = false;
        }, 20);
      }
    });
  };
  $(() => {
    responsiveTableDropdown();
  });
  const calculateJournalAmount = async (signal: AbortSignal) => {
    let flag = true;
    let sumCredit = 0;
    let sumDebit = 0;
    let creditDebitDiff = true;
    let lineItemsAmount: {
      debit: number;
      credit: number;
      index: number | null;
      conversion_rate: number | string;
    }[] = [];
    Array.isArray(props.formik.values.item_details) &&
      props.formik.values.item_details.map(
        (item: JournalItemValues, index: number) => {
          lineItemsAmount.push({
            debit: item.debit ? Number(item.debit) : 0,
            credit: item.credit ? Number(item.credit) : 0,
            index: index || 0,
            conversion_rate:
              item.currency_id === props.formik.values.currency_id
                ? 1
                : item.conversion_rate || 1,
          });
          if (
            (item.debit === "" || item.debit === 0) &&
            (item.credit === "" || item.credit === 0)
          ) {
            flag = false;
          }
        }
      );
    lineItemsAmount.map((item) => {
      sumCredit = sumCredit + item["credit"];
      sumDebit = sumDebit + item["debit"];
      const decimals = decimalPlaceOfCurrency(
        props.currencyCode,
        orgCurrencyList
      );
      if (
        getRoundOffAmount(sumCredit, decimals) -
          getRoundOffAmount(sumDebit, decimals) !==
        0
      ) {
        creditDebitDiff = false;
      } else {
        creditDebitDiff = true;
      }
    });
    if (props.formik.values.item_details.length && flag && creditDebitDiff)
      setAccountAmountExist(true);
    else setAccountAmountExist(false);
    if (props.currencyId) {
      const responseAction = await dispatch(
        getAmountCalculation({
          currencyId: props.currencyId,
          values: lineItemsAmount,
          orgId: props.organizationId,
          signal: signal,
        })
      );
      if (responseAction.payload) {
        const response = responseAction.payload;
        if (Object.keys(response).length && !("error" in response)) {
          setTotalAmountDebit(
            response.total_amount_debit
              ? response.total_amount_debit
              : zeroDisplayFormat(
                  decimalPlaceOfCurrency(
                    props.currencyCode
                      ? props.currencyCode
                      : currentUserInfo.currency_code,
                    orgCurrencyList
                  )
                )
          );
          Object.keys(response.converted_dict)?.map((key) => {
            props.formik.setFieldValue(
              `item_details.${Number(key)}.converted_debit_rate`,
              (
                Object.values(response.converted_dict)[
                  Number(key)
                ] as ObjectType
              ).debit
            );
            props.formik.setFieldValue(
              `item_details.${Number(key)}.converted_credit_rate`,
              (
                Object.values(response.converted_dict)[
                  Number(key)
                ] as ObjectType
              ).credit
            );
          });
          setTotalAmountCredit(
            response.total_amount_credit
              ? response.total_amount_credit
              : zeroDisplayFormat(
                  decimalPlaceOfCurrency(
                    props.currencyCode
                      ? props.currencyCode
                      : currentUserInfo.currency_code,
                    orgCurrencyList
                  )
                )
          );

          setDifferenceDebit(
            response.difference_debit ? response.difference_debit : ""
          );
          setDifferenceCredit(
            response.difference_credit ? response.difference_credit : ""
          );
          if (
            Number(response.difference_debit) === 0 &&
            Number(response.difference_credit) === 0 &&
            (Number(response.total_amount_credit) !== 0 ||
              Number(response.total_amount_debit) !== 0)
          ) {
            props.formik.setFieldValue("is_amount_equal", true);
          } else {
            props.formik.setFieldValue("is_amount_equal", false);
          }
        }
      }
    }
  };
  // Get vendors List drop down Api
  const getSearchVendorsList = async () => {
    await dispatch(vendorDropdownList(props.organizationId));
  };
  // Get customers List drop down Api
  const getSearchCustomersList = async () => {
    await dispatch(customerDropdownList(props.organizationId));
  };
  const getExchangeRateValues = (values: CurrencyList, index: number) => {
    const checkIndex = index;
    let currencyIdToCheck = conversions[checkIndex].toCurrencyId;
    let sameIndices = conversions.map((conversion) => {
      if (
        Number(conversion.toCurrencyId) === Number(currencyIdToCheck) &&
        Number(conversion.index) !== Number(checkIndex)
      ) {
        return conversion.index;
      }
    });
    if (
      Number(currentUserInfo.organization_currency) ===
      Number(currencyIdToCheck)
    ) {
      props.getExchangeRateValueUpdates &&
        props.getExchangeRateValueUpdates(values);
      props.setConversionId && props.setConversionId(values.id);
    }
    const items = [...props.formik.values.item_details];
    if (index) {
      items[index] = {
        ...items[index],
        conversion_id: values.id,
        conversion_rate: values.rate,
        index: index,
      };
    }
    sameIndices.length > 0 &&
      (sameIndices as number[]).map((matchIndex: number) => {
        items[matchIndex] = {
          ...items[matchIndex],
          conversion_id: values.id,
          conversion_rate: values.rate,
          index: matchIndex,
        };
      });
    props.formik.setFieldValue("item_details", items);
    return values;
  };
  return (
    <section className="item-details-wrapper jv-item-details">
      <div className="d-flex justify-content-between align-items-center mb-4 jv-item-details-header-wrapper">
        <h2 className="mb-0">System Voucher Details</h2>
        <div className="info-container table-info">
          <img src={AccessInfoImg} alt="info icon" />
          <span>The Debits and Credits fields need to be equal</span>
        </div>
      </div>
      <div className="row g-0 mb-0">
        <div className="col items-entry-wrapper jv-items-entry-wrapper px-0 overflow-hidden">
          <div className="jv-item-container">
            {/* table start */}
            <div className="item-table-wrapper">
              <table className="table mb-0 item-detail-table jv-detail-table">
                <thead className="border-top-0">
                  <tr>
                    <th>
                      ACCOUNT <span className="jv-required-field"></span>
                    </th>
                    <th>CUSTOMER / VENDOR</th>
                    <th>DESCRIPTION</th>
                    <th>DEBITS</th>
                    <th>CREDITS</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(props.formik.values.item_details) &&
                    props.formik.values.item_details.map(
                      (item: JournalItemValues, index: number) => {
                        return (
                          <JournalItem
                            index={index}
                            item={item}
                            handleItemAccountChange={handleItemAccountChange}
                            itemFormErrors={
                              props.formik.errors.item_details as ObjectType[]
                            }
                            handleItemOnBlur={handleItemOnBlur}
                            onDeleteItemClick={(e) => {
                              e.preventDefault();
                              deleteItemContainer(index);
                              setRemoveItemContainer(!removeItemContainer);
                            }}
                            getExchangeRateValues={getExchangeRateValues}
                            currencyTriggerFlag={props.currencyTriggerFlag || 0}
                            dateTriggerFlag={props.dateTriggerFlag || 0}
                            formik={props.formik}
                          />
                        );
                      }
                    )}
                </tbody>
              </table>
            </div>
            {/* table end */}
          </div>
          <span className="error"></span>
          <div className="add-line add-line-btn-wrapper">
            <a
              href="#"
              role="button"
              className="add-tags-button d-flex align-items-center"
              onClick={(e) => {
                e.preventDefault();
                addItemContainer();
              }}
            >
              <img src={AddTagIcon} alt="" />
              <span className="px-2">Add another line</span>
            </a>
            <span className="error">{props.formErrors.items}</span>
          </div>
          <JournalItemTotal
            currencyCode={props.currencyCode}
            totalAmountDebit={totalAmountDebit}
            totalAmountCredit={totalAmountCredit}
            differenceDebit={differenceDebit}
            differenceCredit={differenceCredit}
          />
        </div>
      </div>
    </section>
  );
};
export default SystemVoucherItemDetails;
