import { useEffect, useState } from "react";
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { downloadUsingURL } from "../../../../../helpers/helper";
import {
  InvoiceDetailValues,
  ObjectType,
  SubscriptionDetailValues,
} from "../../../../../types";
import {
  currentUserSelector,
  setLoaderState,
} from "../../../../common/commonSlice";
import SendEmailModal from "../../../../common/components/SendEmailModal";
import {
  getInvoicePdfLink,
  invoiceDetails,
  invoiceList,
  sendInvoiceData,
} from "../../invoiceSlice";
import DeleteInvoice from "../Delete";
import InvoicePreviewBody from "./InvoicePreviewBody";
import Header from "../../../../common/components/detailsPage/Header";
import HeaderBody from "../../../../common/components/detailsPage/HeaderBody";
import RecordPaymentModal from "../recordPaymentModal/RecordPaymentModal";
import { setErrorID } from "../../../../../appSlice";
import { noAccessErrorCodes } from "../../../../constants/constants";
import Edit from "../../../../common/components/kebabMenu/Edit";
import Delete from "../../../../common/components/Delete";
import { WriteOffModal } from "../writeOffInvoice/WriteOffInvoiceModal";
import KebabMenu from "../../../../common/components/kebabMenu/KebabMenu";
import { EmailIcon } from "../../../../common/components/customSvgIcons/emailIcon";
import { colors } from "../../../../constants/colors";
import { DownloadIcon } from "../../../../common/components/customSvgIcons/downloadIcon";
import { PaymentIcon } from "../../../../common/components/customSvgIcons/paymentIcon";
import usePreservedLocation from "../../../../hooks/usePreservedLocation";
import { CreateCreditNoteAlertModal } from "./createCreditNote/createCreditNoteAlertModal";
import TagDarkIcon from "../../../../common/components/customSvgIcons/tagDarkIcon";
import { ErrorToaster } from "../../../../../helpers/toastHelper";
import { subscriptionDetails } from "../../../subscription/subscriptionSlice";
import { subscriptionDetailsInitialValues } from "../../../subscription/SubscriptionStateInitialization";
import InvoicePreviewBodySubscription from "./InvoicePreviewBodySubscription";
import { VoidConfirmationModal } from "../../../../common/components/voidConfirmationPopup/VoidConfirmationModal";
import { message } from "../../../../constants/messages";
import AlertModalPopup from "../../../../common/components/alertModalPopup/AlertModalPopup";

function InvoicePreviewHeader() {
  type LocationProps = {
    redirectTo?: string;
  };
  const location = usePreservedLocation();
  const dispatch = useAppDispatch();
  const currentUserInfo = useAppSelector(currentUserSelector);
  const navigate = useNavigate();
  const locationState = location.state as LocationProps;
  const redirectTo = locationState?.redirectTo;
  const { editInvoiceId } = useParams();
  const [searchParams] = useSearchParams();
  let redirectUrl = searchParams.get("redirect");
  const type = searchParams.get("type");
  const [emailList, setEmailList] = useState<string[] | []>([]);
  const [deleteInvoiceId, setDeleteInvoiceId] = useState(-1);
  const [deleteInvoiceItemId, setDeleteInvoiceItemId] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [dateSortOrder, setDateSortOrder] = useState("ASC");
  const [openModalFlag, setOpenModalFlag] = useState(false);
  const [reRenderFlag, setReRenderFlag] = useState(0);
  const [overviewSubscriptionData, setOverviewSubscriptionData] =
    useState<SubscriptionDetailValues>(subscriptionDetailsInitialValues);
  const previewDetailsInitialValues = {
    id: "",
    customer_id: "",
    user_id: "",
    organization_id: "",
    invoice_status: "",
    customer_name: "",
    customer_notes: "",
    customer_gstin: "",
    currency_code: "",
    currency_id: null,
    custom_fields: {},
    invoice_number: "",
    invoice_date: "",
    payment_term_id: "",
    due_date: "",
    address_details: [],
    sales_invoice_files: [],
    item_details: [],
    total_details: {
      total_taxable_amount: "",
      tax_amount: "",
      total: "",
      taxes: [],
      payment_made: "",
      tds_percentage: 0,
      transaction_tds_amount: 0,
    },
    email_to: [],
    organization_details: {
      organization_name: "",
      organization_gstin: "",
      email: "",
      country: "",
      state: "",
      address: "",
      logo_path: "",
      phone_number: "",
      zip_code: "",
      city: "",
    },
    is_mutable: true,
    reference_number: "",
    payment_term: "",
    is_locked: false,
    lock_date: "",
    excess_credit: 0,
    tcs_total: 0,
    tds_level: "",
    tds_name: null,
    tds_percentage: null,
    tcs_name: null,
    tcs_percentage: null,
    place_of_supply: "",
    write_off_id: null,
    is_fiscal_closed: false,
    entity_tag: [],
    is_deferred_associated: false,
  };
  const [invoiceData, setInvoiceData] = useState<InvoiceDetailValues>(
    previewDetailsInitialValues
  );
  const [invoiceToPay, setInvoiceToPay] = useState<ObjectType>({});

  const organizationRole = JSON.parse(
    sessionStorage.getItem("organizationRole") || "{}"
  );
  let invoicesRolePermission = organizationRole.length
    ? organizationRole
        .filter((orgPermission: any) => orgPermission.subModule === "Invoices")
        .map((permission: any) => {
          return permission.permission;
        })
    : [];
  let paymentsReceivedPermission = organizationRole.length
    ? organizationRole
        .filter(
          (orgPermission: any) =>
            orgPermission.subModule === "Payments Received"
        )
        .map((permission: any) => {
          return permission.permission;
        })
    : [];

  /**
   * Fetch Invoice Details
   */
  useEffect(() => {
    if (editInvoiceId) {
      invoiceDetail();
    }
  }, [editInvoiceId, currentUserInfo.organization_id]);

  /**
   * Set the entered emails in the invoice data to a state.
   */
  useEffect(() => {
    let emails = invoiceData.email_to.map((data) => {
      return data.email;
    });
    setEmailList(emails);
  }, [invoiceData]);

  const invoiceDetail = async () => {
    dispatch(setLoaderState(true));
    const responseAction = await dispatch(
      invoiceDetails({
        invoiceId: Number(editInvoiceId),
        orgId: currentUserInfo.organization_id,
      })
    );
    if (responseAction.payload) {
      setTimeout(function () {
        dispatch(setLoaderState(false));
      }, 500);
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        if (response.invoice_status === "Draft") {
          navigate("/invoice");
        }
        setInvoiceData(response);
      } else {
        dispatch(setErrorID(noAccessErrorCodes.invoiceDetail));
        // redirectTo ? navigate(redirectTo) : navigate("/invoice");
      }
    }
  };

  /**
   * Back button handler from Invoice details page
   */
  const backFromInvoiceViewHandler = () => {
    redirectUrl ? navigate(redirectUrl) : navigate(-1);
    // if (editInvoiceId) {
    //   redirectTo ? navigate(redirectTo) : navigate("/invoice");
    // } else {
    //   redirectTo ? navigate(redirectTo) : navigate("/invoice/create");
    // }
  };

  const fetchInvoiceList = async (action: string) => {
    await dispatch(
      invoiceList({
        page,
        itemsPerPage,
        dateSortOrder,
        orgId: currentUserInfo.organization_id,
        orgIds: [],
        statusList: [],
        customerList: [],
        invoiceType: [],
      })
    );
    if (action === "delete") {
      // redirectTo ? navigate(redirectTo) : navigate("/invoice");
      redirectUrl ? navigate(redirectUrl) : navigate(-1);
    }
  };

  const downloadPdf = async () => {
    const responseAction = await dispatch(
      getInvoicePdfLink({
        invoiceId: Number(editInvoiceId),
        orgId: currentUserInfo.organization_id,
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (
        Object.keys(response).length &&
        !("error" in response) &&
        response.download_link?.length > 1
      ) {
        let result = await downloadUsingURL(
          response.download_link,
          Number(editInvoiceId) + ".pdf"
        );
        if (result) {
          toast.success("Invoice downloaded successfully!", {
            toastId: "invoice-download-success",
            closeButton: false,
            position: "top-center",
          });
        }
      }
    }
  };

  const sendEmail = async (id: number, emailList: string[]) => {
    const responseAction = await dispatch(
      sendInvoiceData({
        invoiceId: id,
        emails: emailList,
        orgId: currentUserInfo.organization_id,
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        fetchInvoiceList("send");
      }
    }
    return responseAction;
  };

  const reRenderJournal = () => {
    setReRenderFlag(Math.floor(Math.random() * 1000));
  };

  return (
    <>
      <Header
        onBackButtonClick={backFromInvoiceViewHandler}
        data={{
          num: invoiceData.invoice_number,
          status: invoiceData.invoice_status,
        }}
        dataId={editInvoiceId}
      />
      <div className="card-body">
        <HeaderBody
          dataId={editInvoiceId}
          data={{
            num: invoiceData.invoice_number,
            status: invoiceData.invoice_status,
            userName: invoiceData.customer_name,
            date: invoiceData.invoice_date,
            orgName: invoiceData.organization_details.organization_name,
            entity_tag: invoiceData.entity_tag ? invoiceData.entity_tag : [],
          }}
          isSameOrg={
            invoiceData.organization_id === currentUserInfo.organization_id
          }
        >
          {invoicesRolePermission.includes("Update") &&
          !invoiceData.is_fiscal_closed &&
          !invoiceData.is_subscription ? (
            <Edit
              url={`/invoice/edit/${invoiceData.id}?replace=true${
                redirectUrl ? "&redirect=" + redirectUrl : ""
              }`}
              isMutable={true} //change to true after implementing edit of closed and partially paid invoice.
              name="Invoice"
              isLocked={invoiceData.is_locked}
              isVoid={invoiceData.invoice_status === "Void"}
              className=""
              lockDate={invoiceData.lock_date}
              isJvGenerated={invoiceData.is_deferred_associated}
            />
          ) : null}
          {invoicesRolePermission.includes("Delete") &&
          !invoiceData.is_fiscal_closed ? (
            <Delete
              className="text-decoration-none delete-item"
              deleteIcon={true}
              isMutable={invoiceData.is_mutable}
              isLocked={invoiceData.is_locked}
              lockDate={invoiceData.lock_date}
              name="Invoice"
              onClick={() => {
                if (invoiceData.is_mutable) {
                  setDeleteInvoiceId(Number(invoiceData.id));
                  setDeleteInvoiceItemId(invoiceData.invoice_number);
                }
              }}
              isJvGenerated={invoiceData.is_deferred_associated}
            />
          ) : null}
          <KebabMenu wrapperClass="detail-top-action-menu">
            <li>
              <button
                className="dropdown-item"
                data-bs-toggle="modal"
                data-bs-target="#emailToModal"
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                <span>
                  <EmailIcon color={colors.battleshipGrey} />
                  Send Email
                </span>
              </button>
            </li>
            <li>
              <button
                type="button"
                onClick={downloadPdf}
                className="dropdown-item"
              >
                <span>
                  <DownloadIcon color={colors.battleshipGrey} />
                  Download PDF
                </span>
              </button>
            </li>
            {(paymentsReceivedPermission.includes("Create") &&
              invoiceData.invoice_status !== "Draft" &&
              invoiceData.invoice_status !== "Closed") ||
            (invoiceData.invoice_status === "Closed" &&
              invoiceData.write_off_id !== null) ? (
              <li>
                <WriteOffModal
                  invoice_id={Number(editInvoiceId)}
                  invoice_amount={invoiceData.total_details.balance_due}
                  currency_code={invoiceData.currency_code}
                  fetchInvoiceDetail={invoiceDetail}
                  onCloseFunction={reRenderJournal}
                  transactionDate={invoiceData.invoice_date}
                  isVoid={invoiceData.invoice_status === "Void"}
                />
              </li>
            ) : null}
            <li>
              <CreateCreditNoteAlertModal
                creditNoteData={invoiceData}
                isCreationPossible={
                  invoiceData.entity_tag?.includes("Deferred Income")
                    ? false
                    : true
                }
                isVoid={invoiceData.invoice_status === "Void"}
              />
            </li>
            {currentUserInfo.is_tags_enabled === true && (
              <li>
                <button
                  type="button"
                  onClick={() => {
                    if (
                      invoiceData.invoice_status === "Void" &&
                      !invoiceData.is_tags_associated
                    ) {
                      ErrorToaster(
                        message().invoice.associateTagsToVoidInvoice
                      );
                    } else {
                      navigate(
                        `/invoice/edit/${Number(
                          invoiceData.id
                        )}/reporting-tags`,
                        { replace: true }
                      );
                    }
                  }}
                  className="dropdown-item"
                >
                  <span>
                    <TagDarkIcon color={colors.battleshipGrey} />
                    Reporting Tags
                  </span>
                </button>
              </li>
            )}
            {invoiceData && (
              <li>
                <VoidConfirmationModal
                  transactionNumber={invoiceData.invoice_number}
                  transactionStatus={invoiceData.invoice_status}
                  module="Invoice"
                  isTransactionExist={!invoiceData.is_mutable}
                  transactionId={Number(invoiceData.id)}
                  onSubmit={async () => {
                    await invoiceDetail();
                    reRenderJournal();
                  }}
                  isFiscalClosed={invoiceData.is_fiscal_closed}
                />
              </li>
            )}
          </KebabMenu>
        </HeaderBody>
        {invoiceData.is_subscription ? (
          <InvoicePreviewBodySubscription
            invoiceData={invoiceData}
            reRenderFlag={reRenderFlag}
            invoiceDetail={invoiceDetail}
            editInvoiceId={editInvoiceId}
          />
        ) : (
          <InvoicePreviewBody
            invoiceData={invoiceData}
            reRenderFlag={reRenderFlag}
            invoiceDetail={invoiceDetail}
          />
        )}

        <SendEmailModal
          emailLists={emailList}
          id={Number(editInvoiceId)}
          sendEmail={(id: number, emailList: string[]) =>
            sendEmail(id, emailList)
          }
        />
        <DeleteInvoice
          deleteInvoiceId={deleteInvoiceId}
          deleteInvoiceItemId={deleteInvoiceItemId}
          refreshInvoices={fetchInvoiceList}
          organizationId={currentUserInfo.organization_id}
        />
        {/* <RecordPaymentModal
          invoiceToPay={invoiceToPay}
          openModal={openModalFlag}
          closeRecordPaymentModal={() => {
            setOpenModalFlag(false);
            setInvoiceToPay({});
          }}
          fetchInvoice={invoiceDetail}
        /> */}
      </div>
    </>
  );
}

export default InvoicePreviewHeader;
