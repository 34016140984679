import { customFieldsPayloadSetter } from "../../../../../../helpers/customFieldsPayloadSetter";
import { getFormatedDate } from "../../../../../../helpers/helper";
import { ObjectType } from "../../../../../../types";
import {
  GST_TAX_TYPES,
  sourceTaxTypes,
  tdsApplyLevel,
} from "../../../../../constants/constants";
import { TaxItemValues, TaxableAmountCalculationValues } from "../types";

const getFilteredTaxItems = (taxes: TaxItemValues[]) => {
  return taxes
    .map((tax) => {
      if (tax) {
        return {
          tax_id: tax.tax_id || null,
          tax_code: tax.tax_code || "",
          tax_percentage: tax.tax_percentage || 0,
        };
      }
    })
    .filter((tax) => tax && tax?.tax_id !== null);
};

const gstDetailSetter = (gstObject: ObjectType) => {
  if (gstObject && gstObject["is_group"] !== undefined) {
    let gstPercentagesObject: ObjectType = {};
    const getGSTPercentage = (data: {
      tax_id: number | null;
      tax_percentage: number | string;
      tax_type: string;
    }) => {
      switch (data.tax_type) {
        case GST_TAX_TYPES.CGST:
          return { cgst_percentage: data.tax_percentage };
        case GST_TAX_TYPES.SGST:
          return { sgst_percentage: data.tax_percentage };
        case GST_TAX_TYPES.CESS:
          return { cess_percentage: data.tax_percentage };
        case GST_TAX_TYPES.IGST:
          return { igst_percentage: data.tax_percentage };
        case GST_TAX_TYPES.UTGST:
          return { utgst_percentage: data.tax_percentage };
        default:
          return {};
      }
    };
    if (gstObject["is_group"] === true) {
      Array.isArray(gstObject["gst_tax_item"]) &&
        gstObject["gst_tax_item"]?.map(
          (gst: {
            tax_id: number | null;
            tax_percentage: number | string;
            tax_type: string;
          }) => {
            gstPercentagesObject = {
              ...gstPercentagesObject,
              ...getGSTPercentage(gst),
            };
          }
        );
    } else {
      gstPercentagesObject = {
        ...gstPercentagesObject,
        ...getGSTPercentage(
          gstObject as {
            tax_id: number | null;
            tax_percentage: number | string;
            tax_type: string;
          }
        ),
      };
    }
    gstPercentagesObject = {
      ...gstPercentagesObject,
      gst_id: gstObject["tax_id"],
      is_group: gstObject["is_group"],
    };
    return gstPercentagesObject;
  }
};
export const amountCalculationPayloadRefiner = (values: ObjectType) => {
  const {
    items,
    tds_percentage,
    default_tds_percentage,
    tds_amount,
    tcs_percentage,
    tcs_amount,
    currency_id,
    settings,
    total,
    rcm_enabled,
  } = values;

  let filteredItems: TaxableAmountCalculationValues[] = items.map(
    (item: TaxableAmountCalculationValues) => {
      return {
        item_name: item.item_name,
        quantity: item.quantity || 1,
        rate: item.rate || 0,
        discount: item.discount || 0,
        discount_type: item.discount_type || "Absolute",
        taxes: getFilteredTaxItems(item.taxes) || [],
        gst_details: gstDetailSetter(item["gst_tax"] as ObjectType),
        tds_amount: item.tds_amount ? item.tds_amount : 0,
        tds_percentage: item.tds_percentage ? item.tds_percentage : 0,
        default_tds_percentage: item.default_tds_percentage
          ? item.default_tds_percentage
          : 0,
      };
    }
  );
  return {
    item_list: filteredItems,
    currency_id: currency_id ? currency_id : null,
    default_tds_percentage: default_tds_percentage ? default_tds_percentage : 0,
    tds_percentage: tds_percentage ? tds_percentage : 0,
    tds_amount: tds_amount ? tds_amount : 0,
    tcs_percentage: tcs_percentage ? tcs_percentage : 0,
    tcs_amount: tcs_amount ? tcs_amount : 0,
    roundoff_enabled: settings.is_round_off_enabled
      ? settings.is_round_off_enabled
      : false,
    is_total_editable: settings.is_manual_edit_enabled
      ? settings.is_manual_edit_enabled
      : false,
    total: total === 0 ? null : total,
    rcm_enabled: rcm_enabled ? rcm_enabled : false,
  };
};
export const taxableAmountPayloadRefiner = (
  values: TaxableAmountCalculationValues
) => {
  const {
    item_name,
    quantity,
    rate,
    discount,
    discount_type,
    taxes,
    gst_tax,
    tds_amount,
    default_tds_percentage,
    tds_percentage,
  } = values;
  return {
    item_name: item_name || "",
    quantity: quantity || 1,
    rate: rate || 0,
    discount: discount || 0,
    discount_type: discount_type || "Absolute",
    taxes: getFilteredTaxItems(taxes) || [],
    gst_details: gstDetailSetter(gst_tax as ObjectType) || {},
    tds_amount: tds_amount ? tds_amount : 0,
    tds_percentage: tds_percentage ? tds_percentage : 0,
    default_tds_percentage: default_tds_percentage ? default_tds_percentage : 0,
  };
};
const filterItemTax = (taxes: ObjectType[]) => {
  let tempTaxes: ObjectType[] = [];
  if (taxes.length) {
    tempTaxes = taxes.filter((tax) => tax.tax_id && tax.tax_percentage);
  }
  return tempTaxes;
};
export const invoiceSubmitPayloadRefiner = (
  values: ObjectType,
  isDeferredRemoved?: boolean
) => {
  const {
    customer_id,
    customer_name,
    invoice_number,
    reference_number,
    invoice_date,
    payment_term_id,
    due_date,
    customer_notes,
    custom_fields_array,
    tds_id,
    tds_amount,
    tds_account_id,
    tds_percentage,
    default_tds_percentage,
    tcs_id,
    tcs_amount,
    tcs_account_id,
    tcs_percentage,
    place_of_supply,
    source_tax_type,
    total,
    rounddoff_account_id,
    is_total_editable,
    conversion_id,
    conversion_timestamp,
    tag_ids,
    file_ids,
    customer_details,
    items,
    emails,
    address_details,
    recurring_invoice_id,
    currency_id,
    is_locked,
    lock_date,
    invoice_status,
    is_mutable,
    recalculate_exchange_rate,
    tds_name,
    tcs_name,
    settings,
    proforma_id,
    is_deferred,
    deferred_conf,
  } = values;
  let lineItemTDSEnabled = false;
  let sourceTaxType = sourceTaxTypes.TCS;

  const filteredItems = items.map((item: any) => {
    if (item?.tds_id) {
      lineItemTDSEnabled = true;
      sourceTaxType = sourceTaxTypes.TDS;
    }

    return {
      item_name: item.item_name,
      item_id: item.item_id ? item.item_id : null,
      id: item.id !== 0 ? item.id : null,
      account_id: item.account_id,
      deferred_income_id: isDeferredRemoved
        ? null
        : item.deferred_income_id || null,
      deferred_discount_account: isDeferredRemoved
        ? null
        : item.deferred_discount_account || null,
      hsn_or_sac_code: item.hsn_or_sac_code || null,
      inventory_item_id: item.inventory_item_id,
      quantity: item.quantity,
      rate: item?.rate ? item.rate : 0,
      discount: item?.discount ? item.discount : 0,
      discount_account_id: item?.discount_account_id
        ? item.discount_account_id
        : null,
      deferred_discount_id: isDeferredRemoved
        ? null
        : item.deferred_discount_id || null,
      discount_type: item?.discount_type ? item.discount_type : "",
      tds_id: item?.tds_id ? item.tds_id : null,
      tds_amount: item?.tds_amount ? item.tds_amount : 0,
      default_tds_percentage: item?.default_tds_percentage
        ? item?.default_tds_percentage
        : 0,
      tds_percentage: item?.tds_percentage ? item.tds_percentage : 0,
      tds_account_id: item?.tds_account_id ? item.tds_account_id : null,
      gst_id: item.gst_tax?.tax_id ? item.gst_tax.tax_id : null,
      item_exemption_type: item?.item_exemption_type
        ? item.item_exemption_type
        : "",
      item_exemption_id: item?.item_exemption_id
        ? item.item_exemption_id
        : null,
      taxes: filterItemTax(item.taxes),
      gst_tax: item.gst_tax || null,
      is_deferred_item: item.is_deferred_item || false,
    };
  });
  let tds_level_value = tdsApplyLevel.NO_TDS;
  if (tds_id) {
    sourceTaxType = sourceTaxTypes.TDS;
    tds_level_value = tdsApplyLevel.TRANSACTION_LEVEL;
  } else if (lineItemTDSEnabled) {
    tds_level_value = tdsApplyLevel.LINE_ITEM_LEVEL;
  }
  const billingAddress = address_details.billing_address?.filter(
    (addr: any) => addr.is_primary
  );
  const shippingAddress = address_details.shipping_address?.filter(
    (addr: any) => addr.is_primary
  );
  const address = [];
  if (billingAddress && billingAddress.length > 0) {
    address.push(billingAddress[0]);
  }
  if (shippingAddress && shippingAddress.length > 0) {
    address.push(shippingAddress[0]);
  }
  return {
    customer_id: customer_id,
    customer_name: customer_name,
    invoice_number: invoice_number,
    invoice_date: invoice_date,
    payment_term_id: payment_term_id ? payment_term_id : null,
    due_date: due_date,
    customer_notes: customer_notes,
    tag_ids: tag_ids ? tag_ids : [],
    items: filteredItems,
    email_to: emails ? emails : [],
    conversion_id: conversion_id ? conversion_id : null,
    currency_id: currency_id ? currency_id : null,
    file_ids: file_ids ? file_ids : [],
    reference_number: reference_number,
    is_locked: is_locked,
    lock_date: lock_date,
    custom_fields: customFieldsPayloadSetter(custom_fields_array)
      ? customFieldsPayloadSetter(custom_fields_array)
      : {},
    tds_level: tds_level_value ? tds_level_value : "",
    source_tax_type: sourceTaxType,
    place_of_supply: place_of_supply,
    invoice_status: invoice_status,
    is_mutable: is_mutable,
    recalculate_exchange_rate: recalculate_exchange_rate,
    customer_exemption_type: customer_details?.customer_exemption_type
      ? customer_details.customer_exemption_type
      : "",
    customer_exemption_id: customer_details?.customer_exemption_id
      ? customer_details.customer_exemption_id
      : null,
    tds_id: tds_id ? tds_id : null,
    tds_percentage: tds_percentage ? tds_percentage : 0,
    default_tds_percentage: default_tds_percentage ? default_tds_percentage : 0,
    tds_amount: tds_amount ? tds_amount : 0,
    tds_account_id: tds_account_id ? tds_account_id : null,
    tds_name: tds_name ? tds_name : "",
    tcs_id: tcs_id ? tcs_id : null,
    tcs_amount: tcs_amount ? tcs_amount : 0,
    tcs_percentage: tcs_percentage ? tcs_percentage : 0,
    tcs_account_id: tcs_account_id ? tcs_account_id : null,
    tcs_name: tcs_name ? tcs_name : "",
    total: total ? total : 0,
    roundoff_enabled: settings.is_round_off_enabled || false,
    rounddoff_account_id: rounddoff_account_id ? rounddoff_account_id : null,
    is_total_editable: is_total_editable,
    conversion_date: conversion_timestamp
      ? getFormatedDate(conversion_timestamp)
      : "",
    address: [
      {
        country_id: null,
        address: null,
        city: null,
        state_id: null,
        zip_code: null,
        phone: null,
        is_billing: true,
        is_shipping: false,
      },
      {
        country_id: null,
        address: null,
        city: null,
        state_id: null,
        zip_code: null,
        phone: null,
        is_billing: false,
        is_shipping: true,
      },
    ],
    recurring_invoice_id: recurring_invoice_id ? recurring_invoice_id : null,
    proforma_id: proforma_id,
    is_deferred: is_deferred || false,
    deferred_conf: isDeferredRemoved ? null : deferred_conf || null,
    entity_tag: is_deferred ? ["Deferred Income"] : null,
  };
};
