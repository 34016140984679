import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  ErrorToaster,
  InfoToaster,
  SuccessToaster,
} from "../../../../helpers/toastHelper";
import { en } from "../../../../textConstants";
import { ReportTagFormValues } from "../../../../types";
import {
  AddNewSquarePlusIcon,
  FormInputField,
  TextButton,
} from "../../../common/components";
import { DockedButtonGroup } from "../../../common/components/button/DockedButtonGroup";
import { Container } from "../../../common/components/htmlTags/Container";
import { Form } from "../../../common/components/htmlTags/Form";
import { Label } from "../../../common/components/htmlTags/Label";
import { colors } from "../../../constants/colors";
import useCommonData from "../../../hooks/useCommon";
import "../ReportingTag.css";
import { TagOptionComponent } from "../components/tagOptions/TagOptions";
import { useCheckTagNameUniqueness } from "../hooks/useCheckTagNameUniqueness";
import { useDeleteTag } from "../hooks/useDeleteTag";
import { useInitializeForm } from "../hooks/useInitializeForm";
import {
  createReportingTag,
  deleteReportingTagOption,
  updateReportingTag,
} from "../reportingTagAPIFiles/reportingTagSlice";
import { ReportingTagFormValidation } from "./ReportingTagValidation";
import { payloadRefiner } from "../helpers/payloadRefiner";
import { setLoaderState } from "../../../common/commonSlice";
import { useFetchReportingTagList } from "../hooks/useFetchReportingTagList";
import { InfoMessage } from "../../../common/components/infos/InfoMessage";

type Props = {
  onClose: () => void;
  isEdit?: boolean;
  isClone?: boolean;
  editId?: number;
  tagDetails?: ReportTagFormValues;
  setReportingTagsList: React.SetStateAction<any>;
};
export const ReportTagForm = (props: Props) => {
  const { dispatch, currentUserInfo } = useCommonData();
  const { getReportingTags } = useFetchReportingTagList();
  const [removedOptionIds, setRemovedOptionIds] = useState<number[]>([]);
  const navigate = useNavigate();
  const { initialValues } = useInitializeForm(props.tagDetails, props.isClone);
  const { checkTagNameUniquiness } = useCheckTagNameUniqueness();

  const { deleteTag } = useDeleteTag();
  const formik = useFormik({
    initialValues: { ...initialValues },
    enableReinitialize: true,
    validationSchema: ReportingTagFormValidation,
    validateOnChange: true,
    onSubmit: async (values) => {
      dispatch(setLoaderState(true));
      const responseAction =
        props.isEdit === true
          ? await updateTag(payloadRefiner(values))
          : await dispatch(
              createReportingTag({
                values: payloadRefiner(values),
                orgId: currentUserInfo.organization_id,
              })
            );

      if (!("error" in responseAction.payload)) {
        SuccessToaster(
          props.isEdit === true
            ? "Reporting tag updated successfully!"
            : "Reporting tag created successfully!",
          props.isEdit === true
            ? "reporting-tag-update-success"
            : "reporting-tag-create-success"
        );
        props.onClose && props.onClose();
        getReportingTags(props.setReportingTagsList);
        dispatch(setLoaderState(false));
      } else {
        dispatch(setLoaderState(false));
      }
    },
  });
  const updateTag = async (values: ReportTagFormValues) => {
    let responseAction: any = {};
    if (removedOptionIds.length) {
      try {
        await dispatch(
          deleteReportingTagOption({
            orgId: currentUserInfo.organization_id,
            optionIds: removedOptionIds || [],
          })
        );
        responseAction = await dispatch(
          updateReportingTag({
            values: values,
            orgId: currentUserInfo.organization_id,
            id: Number(props.editId),
          })
        );
      } catch {
        ErrorToaster("Couldn't delete the options. Try again later!");
      }
    } else {
      responseAction = await dispatch(
        updateReportingTag({
          values: values,
          orgId: currentUserInfo.organization_id,
          id: Number(props.editId),
        })
      );
    }
    return responseAction;
  };

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    checkTagNameUniquiness(signal, formik);
    return () => {
      controller.abort();
    };
  }, [formik.values.name]);

  // function to add additional options field
  const addOptions = () => {
    if (formik.values.options.length > 49) {
      InfoToaster(
        "Reporting tag options max limit(50) reached!",
        "option-max-limit-warning"
      );
    } else {
      let newOptions = [...formik.values.options];
      newOptions.unshift({ id: null, name: "" });
      formik.setFieldValue("options", newOptions);
    }
  };

  // function to remove existing options field at an index
  const removeOptions = async (index: number, optionId?: number) => {
    if (optionId) {
      let prevIds: number[] = [...removedOptionIds];
      prevIds.push(optionId);
      setRemovedOptionIds(prevIds);
    }
    let newOptions = [...formik.values.options];
    newOptions.splice(index, 1);
    formik.setFieldValue("options", newOptions);
  };

  return (
    <Container className="reporting-tags-form-container">
      <Form
        id=""
        className="reporting-tags-form"
        onSubmit={formik.handleSubmit}
      >
        <FormInputField
          type="text"
          name="name"
          id="name"
          className={`${
            formik.touched.name && formik.errors.name
              ? "form-control error"
              : "form-control"
          } mw-none`}
          label="Tag Name"
          placeholder="Enter tag name"
          maxLength={50}
          onFocus={() => {
            if (
              formik.values.options.some(
                (option) => option.is_associated === true
              ) &&
              !props.isClone
            ) {
              ErrorToaster(
                "Reporting tag name cannot be updated since it has associated transactions"
              );
            }
          }}
          value={formik.values.name}
          onChange={(e) => {
            if (
              formik.values.options.some(
                (option) => option.is_associated === true
              ) &&
              !props.isClone
            ) {
              return;
            } else {
              formik.handleChange(e);
            }
          }}
          error={
            formik.touched.name && formik.errors.name ? formik.errors.name : ""
          }
          wrapperClass={`mb-3 `}
          disabled={false}
          isRequired={true}
        />
        <Container className="reporting-tags-options-wrapper">
          <Container className="reporting-tags-options-label-wrapper">
            <Container>
              <Label className="fw-bold required" htmlFor="options">
                {en.OPTIONS_LABEL}
              </Label>
            </Container>
            <TextButton
              buttonContent={`Add Another Option`}
              onClick={addOptions}
              wrapperClass="text-btn-Container add-reporting-tag-options-btn"
              type="button"
              isDisabled={formik.values.options.length > 50}
              fontColor={colors.ceruleanBlue}
              fontWeight="normal"
              fontSize="14px"
              startIcon={<AddNewSquarePlusIcon />}
            />
          </Container>
          <Container className="reporting-tags-options-map-container">
            {formik.values.options.map((options, index) => {
              return (
                <TagOptionComponent
                  id={options.id}
                  option={options.name}
                  isTouched={
                    formik?.touched?.options && formik?.touched?.options[index]
                      ? true
                      : false
                  }
                  wrapperClass=""
                  formik={formik}
                  index={index}
                  isEdit={props.isEdit === true}
                  editId={props.editId?.toString() || ""}
                  errors={formik.errors.options}
                  isDisabled={false}
                  isSideIconEnabled={formik.values.options.length > 1}
                  deleteOption={() => {
                    if (options.is_associated === true) {
                      ErrorToaster(
                        "Unable to delete this option as there are transactions associated with it!"
                      );
                    } else {
                      removeOptions(index, Number(options.id));
                    }
                  }}
                />
              );
            })}
          </Container>
        </Container>
        {props.isEdit &&
          Array.isArray(props.tagDetails?.options) &&
          !props.tagDetails?.options?.some(
            (option) => option.is_associated === true
          ) && (
            <InfoMessage
              message="Reporting tag will be updated across the system since it has no
            associated transactions"
            />
          )}

        <DockedButtonGroup
          onSave={(e) => {
            e.preventDefault();
            formik.submitForm();
          }}
          onCancel={() => {
            props.onClose();
          }}
          onDelete={() => {
            deleteTag(Number(props.editId), props.setReportingTagsList);
          }}
          isDeleteButtonDisabled={false}
          isDeleteButtonEnabled={false}
          saveButtonType="submit"
          isSaveDisabled={Object.keys(formik.errors).length ? true : false}
          isEdit={props.isEdit === true}
          isModal={true}
          shouldDockToBottom={true}
        />
      </Form>
    </Container>
  );
};
