import {
  ForwardRefRenderFunction,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { defaultValues } from "./StateInitialization";
import { FormInputField } from "../../../../common/components/formInputField/FormInputField";
import {
  CustomerDefaultValues,
  DefaultDetailsRef,
  ShortCodeRef,
} from "../../../../../types";
import EmailInput from "../../../../common/components/EmailInput";
import PhoneInput from "../../../../common/components/PhoneInput";
import CustomerCode from "./CustomerCode";

type Props = {
  defaultFormValues: CustomerDefaultValues;
  formErrors: any;
  customerId: number;
};

interface ChildInputRef {
  getData: () => { value: string; isValid: boolean };
}

const DefaultDetails: ForwardRefRenderFunction<DefaultDetailsRef, Props> = (
  { defaultFormValues, formErrors, customerId },
  ref
) => {
  const emailRef = useRef<ChildInputRef | null>(null);
  const phoneRef = useRef<ChildInputRef | null>(null);
  const shortCodeRef = useRef<ShortCodeRef | null>(null);
  const [defaultData, setDefaultData] = useState(defaultValues);
  const [customerName, setCustomername] = useState("");

  useImperativeHandle(
    ref,
    () => ({
      defaultData: defaultData,
      isShortCodeExist: shortCodeRef.current?.isShortCodeExist
        ? shortCodeRef.current?.isShortCodeExist
        : false,
    }),
    [defaultData]
  );

  useEffect(() => {
    if (defaultFormValues) setDefaultData(defaultFormValues);
  }, [defaultFormValues]);

  const handleChange = (data: { name: string; value: string | number }) => {
    let { name, value } = data;
    setDefaultData({ ...defaultData, [name]: value });
  };

  return (
    <section className="form-top-section">
      <div className="row custom-row">
        <FormInputField
          type="text"
          name="customer_display_name"
          id="c_display_name"
          className={
            formErrors.customer_display_name
              ? "form-control error"
              : "form-control"
          }
          placeholder="Enter customer display name"
          label="Customer Display Name"
          value={defaultData.customer_display_name}
          onChange={(e) => handleChange(e.target)}
          onInput={(e: any) => setCustomername(e?.target?.value)}
          maxLength={200}
          isRequired={true}
          disabled={customerId ? true : false}
          error={formErrors.customer_display_name}
        />
        <FormInputField
          type="text"
          name="company_name"
          id="company"
          className="form-control"
          placeholder="Enter company name"
          label="Company Name"
          value={defaultData.company_name}
          onChange={(e) => handleChange(e.target)}
          maxLength={200}
        />
      </div>
      <div className="row custom-row">
        <EmailInput
          name="customer_email"
          id="c_email"
          label="Customer Email"
          email={defaultData.customer_email}
          isRequired={false}
          handleChange={handleChange}
          error={formErrors.customer_email}
          ref={emailRef}
        />
        <PhoneInput
          name="customer_phone"
          id="c_phone_number"
          label="Customer Phone Number"
          phone={defaultData.customer_phone}
          isRequired={false}
          handleChange={handleChange}
          error={formErrors.customer_phone}
          ref={phoneRef}
        />
      </div>
      <div className="row custom-row mb-0">
        <CustomerCode
          shortCode={defaultData.short_code}
          customerId={customerId}
          customerName={customerName}
          isRequired={true}
          handleChange={!customerId ? handleChange : () => {}}
          error={formErrors.short_code}
          ref={shortCodeRef}
        />
      </div>
    </section>
  );
};

export default forwardRef(DefaultDetails);
