import useCommonData from "../../../../hooks/useCommon";
import { Container } from "../../htmlTags/Container";
import { Label } from "../../htmlTags/Label";
import InfoTooltipMessage from "../../infoTipMessage/InfoTipMessage";
import "./Discount.css";
import DiscountAccountSelect from "./components/discountAccountSelect";
import { DiscountInput } from "./components/discountInput";
import DiscountToggleButton from "./components/discountTypeToggleButton";
import { DiscountComponentProps } from "./types";

export const Discount = (props: DiscountComponentProps) => {
  const { currentUserInfo } = useCommonData();
  return (
    <Container>
      <Container className="label-wrapper discount" id="discount_account_id">
        <Label className="" htmlFor="discount">
          Discount
        </Label>
        <InfoTooltipMessage
          title={
            "Add the percentage/amount to be deducted from the sub-total as a discount"
          }
          placement="bottom-start"
          iconClassName="discount-info-icon"
          disabled={props.isDisabled ? props.isDisabled : false}
        />
        <Container className="discount-type-wrapper d-flex align-items-center">
          <DiscountToggleButton
            currencyCode={props.currencyCode || currentUserInfo.currency_code}
            handleToggle={(value) => {
              props.onDiscountType && props.onDiscountType(value, props.index);
            }}
            discountType={props.discountType}
            disabled={props.isDisabled ? props.isDisabled : false}
          />
        </Container>
      </Container>
      <Container
        className={`discount-field-wrapper d-flex w-100 ${
          props.error ? "error" : ""
        }`}
      >
        <DiscountInput
          onBlur={(amount) => {
            props.onBlur(String(amount));
          }}
          onDiscountValueChange={(e) => {
            props.onDiscountValueChange(e, props.index);
          }}
          index={Number(props.index)}
          error={props.error}
          currencyCode={props.currencyCode || ""}
          discount={String(props.discount)}
          disabled={props.isDisabled ? props.isDisabled : false}
        />
        <DiscountAccountSelect
          id={""}
          onDiscountAccountChange={(e, index, name, accountType) => {
            props.onDiscountAccountChange &&
              props.onDiscountAccountChange(e, index, name, accountType);
          }}
          discountAccountId={props.discountAccountId}
          discountAccountName={props.discountAccountName}
          isDisabled={props.isDisabled ? props.isDisabled : false}
          index={Number(props.index)}
          error={props.error}
        />
      </Container>
      {<span className="error">{props.error}</span>}
    </Container>
  );
};
